import { Device, DeviceTypeView } from '@api/devices/DevicesAPI';
import DevicesAPIFactory from '@api/devices/DevicesAPIFactory';
import { Firmware } from '@api/firmwares/FirmwareAPI';
import FirmwareAPIFactory from '@api/firmwares/FirmwareAPIFactory';
import { Tag } from '@api/tags_api/TagsAPI';
import TagsAPIFactory from '@api/tags_api/TagsAPIFactory';
import SubmitButton from '@components/ui/inputs/buttons/SubmitButton/SubmitButton';
import ToggleButton from '@components/ui/inputs/buttons/ToggleButton/ToggleButton';
import UploadFileButton from '@components/ui/inputs/buttons/UploadFileButton/UploadFileButton';
import { DropdownFilterInput, TagFilterInput, TextFilterInput } from '@components/ui/inputs/filter/FilterInput';
import DashboardLayout from '@components/ui/layout/DashboardLayout/DashboardLayout';
import ExpandableFilterLayout from '@components/ui/layout/ExpandableFilterLayout/ExpandableFilterLayout';
import withData, { DataProp } from '@components/wrappers/DataLoader/DataLoader';
import { withTooltip } from '@components/wrappers/ToolTip/ToolTip';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEllipsisH, FaTimes } from 'react-icons/fa';
import { useToasts } from 'react-toast-notifications';
import AddEditPanel from './AddEditPanel';
import DeviceRow from './DeviceRow';
import './Devices.css';
import { CSVLink } from "react-csv";
import { useContext } from 'react';
import DeviceFilterContext from '@context/DeviceFilterContext';
import Checkbox from '@components/ui/inputs/checkboxes/Checkbox';

const DevicesDashboard: React.FunctionComponent<DataProp<[Device[], DeviceTypeView[], Firmware[], Tag[], number]>> = ({ data }) => {
    const { t } = useTranslation();
    const filtersContext = useContext(DeviceFilterContext);
    const maxEntries = 8;
    const [devices, setDevices] = useState<Device[]>(data[0]);
    const [devicesCount, setDevicesCount] = useState<number>(data[4]);
    const [editingDevice, setEditingDevice] = useState<Device | null>(null);
    const [searchText, setSearchText] = useState<string>('');
    const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
    const [updatingDevices, setUpdatingDevices] = useState<boolean>(false);
    const [filterName, setFilterName] = useState<string>((filtersContext.getDeviceFilters().name)?filtersContext.getDeviceFilters().name:'');
    const [filterAddress, setFilterAddress] = useState<string>((filtersContext.getDeviceFilters().data?.address)?filtersContext.getDeviceFilters().data?.address:'');
    const [filterTags, setFilterTags] = useState<Tag[]>((filtersContext.getDeviceFilters().tags)?filtersContext.getDeviceFilters().tags:[]);
    const [filterFirmwareVersion, setFilterFirmwareVersion] = useState<string>((filtersContext.getDeviceFilters().firmwareVersion)?filtersContext.getDeviceFilters().firmwareVersion:'-1');
    const [filterTargetFirmwareVersion, setFilterTargetFirmwareVersion] = useState<string>((filtersContext.getDeviceFilters().targetVersion)?filtersContext.getDeviceFilters().targetVersion:'-1');
    const [filterDeviceType, setFilterDeviceType] = useState<string>((filtersContext.getDeviceFilters().deviceType)?filtersContext.getDeviceFilters().deviceType:'-1');
    const [filterMultipleTypes, setFilterMultipleTypes] = useState<boolean>((filtersContext.getDeviceFilters().multiple_types)?filtersContext.getDeviceFilters().multiple_types:false);
    /*const [filterName, setFilterName] = useState<string>('');
    const [filterAddress, setFilterAddress] = useState<string>('');
    const [filterTags, setFilterTags] = useState<Tag[]>([]);
    const [filterFirmwareVersion, setFilterFirmwareVersion] = useState<string>('-1');
    const [filterTargetFirmwareVersion, setFilterTargetFirmwareVersion] = useState<string>('-1');
    const [filterDeviceType, setFilterDeviceType] = useState<string>('-1');*/
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [queryParameters, setQueryParameters] = useState<string>('');
    const [fileUploading, setFileUploading] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<File | null>();
    const [showTag, setShowTag] = useState<string | null>(null);
    const [udpATMenuOpen, setudpATMenuOPen] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [availableFirmwares, setAvailableFirmwares] = useState<Firmware[]>((filtersContext.getDeviceFilters().deviceType)?data[2].filter((elem) => elem.device_type === filtersContext.getDeviceFilters().deviceType):[]);
    const deviceTypes = data[1];
    const firmwares = data[2];
    const tags = data[3];
    const { addToast } = useToasts();
    const fileUploadHandler = (e: any) => {
        setSelectedFile(e.target.files[0]);
    }
    const isInitialMount = useRef(true);
    const isInitialMount2 = useRef(true);

    useEffect(() => {
        submitFilters();
    }, [searchText, filterName, filterAddress, filterDeviceType, filterFirmwareVersion, filterTargetFirmwareVersion, filterTags, filterMultipleTypes]);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            const initialPage = 1;
            fetchDevicesWithQueryParams(initialPage, () => { setCurrentPage(initialPage) });
        }
    }, [queryParameters]);

    useEffect(() => {
        if (isInitialMount2.current) {
            isInitialMount2.current = false;
        } else {
            DevicesAPIFactory.getDevicesAPI().getDevicesFiltered(queryParameters, currentPage, maxEntries).then(results => {
                setDevices(results);
            }).catch(err => {
                addToast('Filter operation failed with error: ' + err, { appearance: 'error', autoDismiss: true });
            });
        }
    }, [currentPage]);


    const selectAllMatchingFilter = () => {
        DevicesAPIFactory.getDevicesAPI().getAllDevicesFiltered(queryParameters).then(results => {
            setSelectAll(true);
            setSelectedDevices(results);
        }).catch(err => {
            addToast('Filter operation failed with error: ' + err, { appearance: 'error', autoDismiss: true });
        });
    };

    const clearAllSelectedDevices = () => {
        setSelectAll(false);
        setSelectedDevices([]);
    }

    const filters: React.ReactNode[] = [
        <TextFilterInput label="Name" updateValue={(newValue: any) => { setFilterName(newValue) }} placeholder="Type to filter by name" presetValue={filterName} />,
        <TextFilterInput label="Address" updateValue={(newValue: any) => { setFilterAddress(newValue) }} placeholder="Type to filter by address" presetValue={filterAddress} />,
        <DropdownFilterInput label="Firmware version" selectedOption={filterFirmwareVersion} onSelect={(newValue: any) => { setFilterFirmwareVersion(newValue) }} placeholder="Firmware version" dropdownOptions={availableFirmwares.map(mech => { return { id: mech.version, label: mech.version } })} />,
        <DropdownFilterInput label="Target firmware version" selectedOption={filterTargetFirmwareVersion} onSelect={(newValue: any) => { setFilterTargetFirmwareVersion(newValue) }} placeholder="Target firmware version" dropdownOptions={availableFirmwares.map(mech => { return { id: mech.version, label: mech.version } })} />,
        <DropdownFilterInput label="Device type" selectedOption={filterDeviceType} onSelect={(newValue: any) => { 
            setFilterDeviceType(newValue); 
            setFilterFirmwareVersion('-1');
            setFilterTargetFirmwareVersion('-1');
            setAvailableFirmwares(firmwares.filter((elem) => elem.device_type === newValue)); 
        }} placeholder="Device type" dropdownOptions={deviceTypes.map(mech => { return { id: mech.name, label: mech.name } })} />,
        <Checkbox label="Multiple Types" checked={filterMultipleTypes} onUpdate={(newVal: boolean) => { setFilterMultipleTypes(newVal) }} />,
        <TagFilterInput label="Tag filter" tags={filterTags} allTags={tags} onTagFilterChanged={(newVal: Tag[]) => { setFilterTags(newVal) }} />
    ];
    const submitFilters = () => {
        let queryParams = [];
        if (searchText != "") {
            queryParams.push("name=" + searchText);
        } else {
            if (filterName != "") {
                queryParams.push("name=" + filterName);
            }
        }
        if (filterAddress != "") {
            queryParams.push("address=" + filterAddress);
        }
        if (filterDeviceType != "-1") {
            queryParams.push("deviceType=" + filterDeviceType);
        }
        if (filterFirmwareVersion != "-1") {
            queryParams.push("fwVersion=" + filterFirmwareVersion);
        }
        if (filterTargetFirmwareVersion != "-1") {
            queryParams.push("targetVersion=" + filterTargetFirmwareVersion);
        }
        if (filterTags.length != 0) {
            queryParams.push("tag=" + filterTags.map(tag => tag.name).join(','));
        }
        queryParams.push("multiple_types=" + filterMultipleTypes);
        let addressFilter:any = {}
        if (filterAddress !== '') {
            addressFilter.address = filterAddress;
        }
        filtersContext.setDeviceFilters({name:filterName, data:addressFilter, 
            tags: filterTags, firmwareVersion: filterFirmwareVersion, targetVersion:filterTargetFirmwareVersion, deviceType:filterDeviceType, multiple_types: filterMultipleTypes});
        setQueryParameters(queryParams.join('&'));
    };
    const resetFilters = () => {
        setFilterName('');
        setFilterAddress('');
        setFilterDeviceType('-1');
        setFilterMultipleTypes(false);
        setFilterFirmwareVersion('-1');
        setFilterTargetFirmwareVersion('-1');
        setFilterTags([]);
    }
    const fetchDevicesWithQueryParams = (pageNumber: number, onDataLoad?: () => void) => {
        Promise.all([DevicesAPIFactory.getDevicesAPI().getDevicesFiltered(queryParameters, pageNumber, maxEntries), DevicesAPIFactory.getDevicesAPI().getDeviceCount(queryParameters)]).then(results => {
            setDevices(results[0]);
            setDevicesCount(results[1]);
            if (onDataLoad) onDataLoad();
        }).catch(err => {
            addToast('Filter operation failed with error: ' + err, { appearance: 'error', autoDismiss: true });
        });
    }

    const deviceTagRemoved = (device: Device, tag: string) => {
        DevicesAPIFactory.getDevicesAPI().removeTagFromDevice(device.name, device.device_type, tag).then(() => {
            let tempDvs = [...devices];
            let idx = tempDvs.findIndex(d => d == device);
            if (idx >= 0) {
                let tagIdx = device.tags.indexOf(tag);
                if (tagIdx >= 0) {
                    device.tags.splice(tagIdx, 1);
                    tempDvs[idx] = device;
                    setDevices(tempDvs);
                }
            }
        }).catch(error => {
        });

    }
    const deviceTagAdded = (device: Device, tag: string) => {
        DevicesAPIFactory.getDevicesAPI().addTagToDevice(device.name, device.device_type, tag).then(() => {
            let tempDvs = [...devices];
            let idx = tempDvs.findIndex(d => d == device);
            if (idx >= 0) {
                device.tags.push(tag);
                tempDvs[idx] = device;
                setDevices(tempDvs);
            }
        }).catch((error) => {
        });
    }

    const deleteDevice = (id: string, deviceType:string) => {
        return new Promise<void>((resolve, reject) => {
            DevicesAPIFactory.getDevicesAPI().deleteDevice(id, deviceType).then(() => {
                fetchDevicesWithQueryParams(currentPage);
                addToast(t('Device deleted'), { appearance: 'success', autoDismiss: true });
                resolve();
            }).catch(error => {
                reject(error);
                addToast(t('Failed to delete device'), { appearance: 'error', autoDismiss: true });
            });
        });
    }

    const editDevice = (deviceType: Device, oldName: string) => {
        return new Promise<void>((resolve, reject) => {
            DevicesAPIFactory.getDevicesAPI().editDevice(deviceType, oldName).then(() => {
                let idx = devices.findIndex(u => u.name == oldName);
                let newUsers = [...devices];
                newUsers[idx] = deviceType;
                setDevices(newUsers);
                addToast(t('Device edited'), { appearance: 'success', autoDismiss: true });
                resolve();
            }).catch(error => {
                reject(error);
                addToast(t('Failed to edit device'), { appearance: 'error', autoDismiss: true });
            });
        });
    }

    const createDevice = (deviceType: Device) => {
        if(deviceType.data.address === '') {
            deviceType.data = {};
        }
        return new Promise<void>((resolve, reject) => {
            DevicesAPIFactory.getDevicesAPI().addDevice(deviceType).then(() => {
                fetchDevicesWithQueryParams(currentPage);
                addToast(t('Device created'), { appearance: 'success', autoDismiss: true });
                resolve();
            }).catch(error => {
                reject(error);
                addToast(t('Failed to create device'), { appearance: 'error', autoDismiss: true });
            });
        });
    }

    const uploadCSVFile = () => {
        if (selectedFile == null) return;
        setFileUploading(true);
        DevicesAPIFactory.getDevicesAPI().uploadDevicesFile(selectedFile).then(() => {
            addToast('File uploaded', { appearance: 'success', autoDismiss: true });
            setFileUploading(false);
            fetchDevicesWithQueryParams(1, () => { setCurrentPage(1) });
        }).catch((error) => {
            setFileUploading(false);
            addToast('File upload failed with error: ' + error, { appearance: 'error', autoDismiss: true });
        });
    }
    const SubmitButtonWithToolTip = withTooltip("CsvToolTip")(SubmitButton)

    return (
        <div style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <DashboardLayout
                tableProps={{
                    selectAll: (selected) => { selected ? setSelectedDevices([...devices]) : clearAllSelectedDevices() },
                    tableHeaders: ["Mac","Name", "Address", "Tags", "Device Type", "Firmware Version", "Version Notified", "Last Communication", "Target Version", "Force Version", ""],
                    tableFooterInfo: {
                        currentPage: currentPage,
                        numberOfEntries: devicesCount,
                        entriesPerPage: maxEntries,
                        pageSelected: (newPage: number) => { setCurrentPage(newPage) }
                    }
                }}
                title="Devices"
                filter={{
                    filter: ExpandableFilterLayout, props: {
                        placeholder: 'Search by name', filterItems: filters, onTextSearchChange: (newValue: string) => { setSearchText(newValue) }, onReset: resetFilters
                    }
                }}
                tableFooter={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <AddEditPanel tags={tags} onEdit={editDevice} onDelete={deleteDevice} onSubmit={createDevice} editMode={editingDevice ? true : false} device={editingDevice ? editingDevice : new Device(-1, '', filtersContext.getDeviceFilters().deviceType?filtersContext.getDeviceFilters().deviceType:'', filtersContext.getDeviceFilters().firmwareVersion?filtersContext.getDeviceFilters().firmwareVersion:'', filtersContext.getDeviceFilters().targetVersion?filtersContext.getDeviceFilters().targetVersion:'',filtersContext.getDeviceFilters().tags?filtersContext.getDeviceFilters().tags.map((tag:any) => {return tag.name}):[], false, {}, new Date().toJSON(), new Date().toJSON(),'')} deviceTypes={deviceTypes} firmwareVersions={firmwares} onClose={() => { setEditingDevice(null); }}></AddEditPanel>
                        <div>
                            <UploadFileButton accept=".csv" handleFile={fileUploadHandler} />
                            <SubmitButtonWithToolTip label="Upload csv file" disabled={selectedFile == null} isLoading={fileUploading} onClick={uploadCSVFile} />
                        </div>
                    </div>
                }
            >
                {devices.map((device, idx) => <DeviceRow bottomHalf={idx >= devices.length / 2} showTagsToggled={(show: boolean) => { show ? setShowTag(device.name) : setShowTag(null) }} showTags={showTag == device.name} selected={selectedDevices.findIndex(dev => (dev.name == device.name) && (dev.device_type == device.device_type)) >= 0} deviceSelected={(newVal: boolean) => { newVal ? setSelectedDevices([...selectedDevices, device]) : setSelectedDevices([...selectedDevices?.filter(dev => dev.name != device.name)]) }} tags={tags} deviceTagAdded={(tag: string) => deviceTagAdded(device, tag)} deviceTagRemoved={(tag: string) => { deviceTagRemoved(device, tag) }} key={device.name} device={device} editingRow={(editingDevice ? (editingDevice.name == device.name ) && (editingDevice.device_type == device.device_type) : false)} editToggled={(toggled: boolean) => { toggled ? setEditingDevice(device) : setEditingDevice(null); }}></DeviceRow>)}
            </DashboardLayout>
            <div className={selectedDevices.length > 0 ? "BottomDrawer expanded" : "BottomDrawer"}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <div className="BottomDrawerTitle">
                        <span>{selectedDevices.length} devices selected.</span>
                        <br />
                        {!selectAll && <>
                            <span>Only devices on this page are selected, </span>
                            <button className="selectAllBtn" onClick={selectAllMatchingFilter} style={{ cursor: 'pointer' }}>click here to select all devices that match this filter</button>
                        </>
                        }
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <SubmitButton disabled={updatingDevices} isLoading={updatingDevices} onClick={() => {
                        setUpdatingDevices(true);
                        DevicesAPIFactory.getDevicesAPI().updateDevices(selectedDevices).then(() => {
                            setUpdatingDevices(false);
                            setSelectedDevices([]);
                            addToast(t('Update command sent'), { appearance: 'success', autoDismiss: true });
                        }).catch(error => {
                            setUpdatingDevices(false);
                            addToast(t('Update command failed') + ' ' + error, { appearance: 'error', autoDismiss: true });
                        });
                    }} label="Update firmware to target versions" />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <div className={udpATMenuOpen ? "Udpatmenu expanded" : "Udpatmenu"}>
                            <CSVLink data={selectedDevices} className="SubmitButton" filename={"devices-export.csv"}>Download selection as CSV</CSVLink>
                            <SubmitButton disabled={false} isLoading={false} onClick={() => {
                                DevicesAPIFactory.getDevicesAPI().requestFirmware(selectedDevices).then(() => {
                                    addToast(t('Version request command sent'), { appearance: 'success', autoDismiss: true });
                                }).catch(error => {
                                    addToast(t('Version request command failed') + ' ' + error, { appearance: 'error', autoDismiss: true });
                                })
                            }} label="Get version" />
                        </div>
                        <ToggleButton active={udpATMenuOpen} backgroundColor="#dedede" icon={FaEllipsisH} onChange={(value) => { setudpATMenuOPen(value); }} size={42} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withData(() => Promise.all([DevicesAPIFactory.getDevicesAPI().getDevices(1, 8), DevicesAPIFactory.getDevicesAPI().getDeviceTypes(), FirmwareAPIFactory.getFirmwareAPI().getFirmwares(), TagsAPIFactory.getTagsAPI().getTags(), DevicesAPIFactory.getDevicesAPI().getDeviceCount('')]))(DevicesDashboard);