import ITagsAPI, { Tag } from './TagsAPI';

class MockTagsAPI implements ITagsAPI {
    deleteTags(tag: Tag[]): Promise<void> {        
        return new Promise<void> (resolve => {
        setTimeout(() => {
            resolve();
        }, 500);
    })
    }
    addTag(tag: Tag): Promise<void> {
        return new Promise<void> (resolve => {
            setTimeout(() => {
                resolve();
            }, 500);
        })
    }
    deleteTag(tag: Tag): Promise<void> {
        return new Promise<void> (resolve => {
            setTimeout(() => {
                resolve();
            }, 500);
        })
    }
    editTag(tag: Tag): Promise<void> {
        return new Promise<void> (resolve => {
            setTimeout(() => {
                resolve();
            }, 500);
        })
    }
    getTags(): Promise<Tag[]> {
        return new Promise<Tag[]>((resolve, reject) => {
            setTimeout(() => {
                let tags = [];
                for (let i = 0; i < 200; i++) {
                    tags.push(new Tag('Tag ' + i));
                }
                resolve(tags);
            }, 500);
        });
    }

}

export default MockTagsAPI;