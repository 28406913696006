import SubmitButton from '@components/ui/inputs/buttons/SubmitButton/SubmitButton';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './FilterLayout.css';

interface FilterLayoutProps {
    active: boolean;
    filterItems: React.ReactNode[];
    onSubmit?: () => void;
    onReset: () => void;
}

const FilterLayout: React.FunctionComponent<FilterLayoutProps> = ({ active, filterItems, onSubmit, onReset }) => {
    const { t } = useTranslation();
    const sidePanelCSS = [
        "FilterSidePanel"
    ];
    if (active) {
        sidePanelCSS.push('expanded');
    }
    const filters = filterItems.map((component, idx) => {
        return (
            <div key={idx}>
                {component}
            </div>
        )
    })
    return (
        <div className={sidePanelCSS.join(' ')}>
            <span className="FilterPanelTitle">{t("Filters")}</span>
            {filters}
            {onSubmit &&
                <div style={{ marginTop: 'auto' }}>
                    <SubmitButton label="Filter" onClick={() => { onSubmit() }} />
                </div>
            }
            <div style={{marginTop: 'auto'}}>
                <SubmitButton label="Reset" onClick={onReset}/>
            </div>

        </div>
    )
}

export default FilterLayout;