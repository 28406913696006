import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconType } from 'react-icons';
import { Link, useRouteMatch } from 'react-router-dom';
import './NavigationLink.css'

interface NavigationLinkProps {
    path: string;
    label: string;
    icon: IconType;
}

const NavigationLink: React.FunctionComponent<NavigationLinkProps> = (({path, label, icon}) => {
    let match = useRouteMatch({
        path: path,
        exact: true
    });
    const { t } = useTranslation();
    return (
        <div className="NavContainer">
            {match && <div className="TabSelected"></div>}
            <Link className={match ? "Link active" : "Link"} to={path}>{t(label)}</Link>
            {icon({className:match ? "Link active" : "Link", style:{marginLeft: 'auto', marginRight: 20}})}
        </div>
    )
});

export default NavigationLink;