import { createContext } from "react";
import { Firmware } from '@api/firmwares/FirmwareAPI';
import { Tag } from "@api/tags_api/TagsAPI";

interface IFirmwareFilterContext {
  filters: any;
  setFirmwareFilters: (filters:any) => void;
  getFirmwareFilters: () => any;
}

const FirmwareFilterContext = createContext<IFirmwareFilterContext>({filters: {}, setFirmwareFilters: (filters:any) => {}, getFirmwareFilters: () => {return {}}});

export default FirmwareFilterContext;