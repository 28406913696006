import { Device, DeviceTypeView } from '@api/devices/DevicesAPI';
import { Firmware } from '@api/firmwares/FirmwareAPI';
import { Tag } from '@api/tags_api/TagsAPI';
import AddButton from '@components/ui/inputs/buttons/AddButton/AddButton';
import DeviceFilterContext from '@context/DeviceFilterContext';
import { Field, Form, Formik, useField, useFormikContext } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceTagsBody } from './DeviceTags';

interface IAddEditPanel {
    editMode: boolean;
    firmwareVersions: Firmware[];
    deviceTypes: DeviceTypeView[];
    device?: Device;
    tags: Tag[];
    onClose: () => void;
    onSubmit: (u: Device) => Promise<any>;
    onEdit: (u: Device, name: string) => Promise<any>;
    onDelete: (id: string, type:string) => Promise<any>;
}

const FirmwareDropdownField = (props: any) => {
    const {
        values: { device_type, targetVersion },
        setFieldValue
    } = useFormikContext<any>();
    const {t} = useTranslation();
    const [field, meta] = useField(props);
    const versionsForDeviceType = props.allVersions.filter((version: Firmware) => version.device_type == device_type);
    useEffect(() => {
        setFieldValue(props.name, targetVersion)
    }, [device_type, targetVersion])
    return (
        <>
            <select defaultValue="-1" {...field}>
                <option value="-1" hidden>{t("Target Version")}</option>
                {props.editMode && 
                <option value="">{t("No Target Version")}</option>
                }
                {
                    versionsForDeviceType.map((version: Firmware) => <option key={version.version} value={version.version}>{version.version}</option>)
                }
            </select>
        </>
    )
}

const TagSelectionField = (props: any) => {
    const {
        values: { },
        setFieldValue
    } = useFormikContext<any>();
    const [selectedTags, setSelectedTags] = useState<string[]>(props.device.tags);
    useEffect(() => {
        setFieldValue(props.name, selectedTags)
    }, [selectedTags]);
    useEffect(()=> {
        setSelectedTags(props.device.tags);
    }, [props.device.tags])
    return (
        <>
            <DeviceTagsBody tags={selectedTags} allTags={props.tags} tagAdded={(tagId: string) => {setSelectedTags([...selectedTags, tagId])}} tagRemoved={(tagId: string) => {setSelectedTags(selectedTags.filter(t => t != tagId))}}/>
        </>
    )
}

const AddEditPanel: React.FunctionComponent<IAddEditPanel> =
    ({ device, editMode, onClose, firmwareVersions, tags, deviceTypes, onSubmit, onEdit, onDelete }) => {
        const filtersContext = useContext(DeviceFilterContext);
        const [dev, setDevice] = useState<Device>(device ? device : new Device(-1, '', '', '', '', [], false, {}, new Date().toJSON(), new Date().toJSON(), ''));
        const [prevDevTypeName, setPrevDevTypeName] = useState<string>(device ? device.name : '');
        const [open, setOpen] = useState(false);
        const [addAnother, setAddAnother] = useState(false);
        const {t} = useTranslation();
        useEffect(() => {
            if (device) {
                console.log('tags',filtersContext.getDeviceFilters().tags);
                setDevice(device);
                setPrevDevTypeName(device.name);
            } else {
                console.log('tags',filtersContext.getDeviceFilters().tags);
                setDevice(new Device(-1, '', (filtersContext.getDeviceFilters().deviceType)?filtersContext.getDeviceFilters().deviceType:'', 
                (filtersContext.getDeviceFilters().firmwareVersion)?filtersContext.getDeviceFilters().firmwareVersion:'', 
                (filtersContext.getDeviceFilters().targetVersion)?filtersContext.getDeviceFilters().targetVersion:'', 
                (filtersContext.getDeviceFilters().tags)?filtersContext.getDeviceFilters().tags:[], false, {}, new Date().toJSON(), new Date().toJSON(), ''));
            }
        }, [device]);
        useEffect(() => {
            setOpen(editMode);
        }, [editMode]);
        return (
            <Formik initialValues={dev} enableReinitialize onSubmit={async (values, {resetForm}) => { editMode ? await onEdit(values as Device, prevDevTypeName).then(() => { onClose(); setOpen(false); resetForm(); }) : await onSubmit(values as Device).then(() => { onClose(); setOpen(addAnother); resetForm(); dev.tags = []; dev.data.address = '' }); }}>
                {({ errors, touched, isSubmitting }) => (
                    <Form>
                        <AddButton submitLabel={editMode ? "Save" : "Submit"}
                            onSubmit={() => Promise.resolve()}
                            onEdit={() => onEdit(dev, prevDevTypeName)}
                            onDelete={() => onDelete(dev.name, dev.device_type)}
                            expanded={open}
                            editing={editMode}
                            openClicked={() => setOpen(true)}
                            enabled={!isSubmitting}
                            onClose={() => { onClose(); setOpen(false) }}>
                            {errors.name && touched.name && <span style={{color: 'red', fontSize: 14}}>{errors.name}</span>}
                            {!editMode &&
                                <Field className={errors.name && touched.name ? "error" : ""} id="name" name="name" placeholder={t("Mac")} validate={(value: any) => value ? undefined : t("*Required")} />
                            }
                            <Field id="display_name" name="display_name" placeholder={t("Name")}/>
                            <Field id="data.address" name="data.address" placeholder={t("Address")} />
                            {errors.device_type && touched.device_type && <span style={{color: 'red', fontSize: 14}}>{errors.device_type}</span>}
                            <Field className={errors.device_type && touched.device_type ? "error" : ""} id="device_type" name="device_type" placeholder={t("Device Type")} as="select" validate={(value: any) => value ? undefined : t("*Required")}>
                                <option value="" hidden>{t("Device Type")}</option>
                                {deviceTypes.map(deviceType => <option key={deviceType.name} value={deviceType.name}>{deviceType.name}</option>)}
                            </Field>
                            <FirmwareDropdownField id="targetVersion" name="targetVersion" editMode={editMode} allVersions={firmwareVersions}/>
                            <TagSelectionField id="tags" name="tags" device={dev} tags={tags}/>
                            <div style={{display:'flex', width:'100%', marginTop:'10px'}}>
                                <div style={{marginRight:'auto', marginLeft:'15px', fontSize:'14px', color:'gray'}}>Force Version</div>
                                <Field style={{marginLeft:'auto',marginRight:'5px'}} type="checkbox" id="force_version" name="force_version"></Field>
                            </div>
                            {!editMode &&
                            <div style={{display:'flex', width:'100%', marginTop:'10px', paddingTop:'20px', borderTop:'1px solid gray'}}>
                                <div style={{marginRight:'auto', marginLeft:'15px', fontSize:'14px', color:'gray'}}>Add another</div>
                                <input type="checkbox" checked={addAnother} onChange={() => setAddAnother(!addAnother)}></input>
                            </div>
                            }
                        </AddButton>
                    </Form>
                )}
            </Formik>
        )
    }

export default AddEditPanel;
