import { Tag } from '@api/tags_api/TagsAPI';
import TagsAPIFactory from '@api/tags_api/TagsAPIFactory';
import AuthContext from '@context/AuthContext';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';
import { PulseLoader } from 'react-spinners';
import { useToasts } from 'react-toast-notifications';
import './Tags.css';

interface ITagView {
    tag: Tag;
    tagRemoved: (tagId: string) => void;
}

const TagView: React.FunctionComponent<ITagView> = ({ tag, tagRemoved }) => {
    const [removingTag, setRemovingTag] = useState<boolean>(false);
    const {addToast} = useToasts();
    const {t} = useTranslation();
    const removeTag = () => {
        setRemovingTag(true);
        TagsAPIFactory.getTagsAPI().deleteTag(tag).then(() => {
            setRemovingTag(false);
            addToast(t('Tag deleted'), { appearance: 'success', autoDismiss: true });
            tagRemoved(tag.name);
        }).catch(() => {
            addToast(t('Failed to delete tag'), { appearance: 'error', autoDismiss: true });
            setRemovingTag(false);
        });
    }
    return (
        <div className="Tag">
            {
                removingTag ? 
                <PulseLoader color="#707070" size={6} />
                : (
                    <div>
                        <span>{tag.name}</span>
                        <FaTimes color={'#707070'} size={10} style={{ marginLeft: '5px', marginRight: '5px' }} onClick={() => { removeTag() }} />
                    </div>
                )
            }

        </div>
    );
}

export default TagView;