import { DeviceTypeView } from '@api/devices/DevicesAPI';
import DevicesAPIFactory from '@api/devices/DevicesAPIFactory';
import { Firmware, Priority } from '@api/firmwares/FirmwareAPI';
import FirmwareAPIFactory from '@api/firmwares/FirmwareAPIFactory';
import { DropdownFilterInput, TextFilterInput } from '@components/ui/inputs/filter/FilterInput';
import DashboardLayout from '@components/ui/layout/DashboardLayout/DashboardLayout';
import ExpandableFilterLayout from '@components/ui/layout/ExpandableFilterLayout/ExpandableFilterLayout';
import withData, { DataProp } from '@components/wrappers/DataLoader/DataLoader';
import FirmwareFilterContext from '@context/FirmwareFilterContext';
import React, { useContext, useEffect, useState } from 'react';
import AddEditPanel from './AddEditPanel';
import FirmwareRow from './FirmwareRow';

const FirmwaresDashboard: React.FunctionComponent<DataProp<[Firmware[], DeviceTypeView[], Priority[]]>> = ({data}) => {
    const filtersContext = useContext(FirmwareFilterContext);
    const [firmwares, setFirmwares] = useState<Firmware[]>(data[0]);
    const [filteredFirmwares, setFilteredFirmwares] = useState<Firmware[]>(data[0]);
    const [deviceTypes] = useState<DeviceTypeView[]>(data[1]);
    const [priorities] = useState<Priority[]>(data[2]);
    const [editingFirmware, setEditingFirmwares] = useState<Firmware | null>(null);
    const [searchText, setSearchText] = useState<string>('');
    const [versionFilter, setVersionFilter] = useState<string>((filtersContext.getFirmwareFilters().version)?filtersContext.getFirmwareFilters().version:'');
    const [firmwareNameFilter, setFirmwareNameFilter] = useState<string>((filtersContext.getFirmwareFilters().name)?filtersContext.getFirmwareFilters().name:'');
    const [deviceTypeFilter, setDeviceTypeFilter] = useState<string>((filtersContext.getFirmwareFilters().deviceType)?filtersContext.getFirmwareFilters().deviceType:'-1');
    const [priorityFilter, setPriorityFilter] = useState<string>((filtersContext.getFirmwareFilters().priority)?filtersContext.getFirmwareFilters().priority:'-1');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const maxEntries = 10;

    useEffect(() => {
        filter();
    }, [searchText, versionFilter, deviceTypeFilter, firmwareNameFilter, priorityFilter, firmwares]);

    useEffect(() => {
        setCurrentPage(1);
    }, [filteredFirmwares]);

    let filters: React.ReactNode[] = [
        <TextFilterInput label="Version" placeholder="Type to filter by version" updateValue={(newValue: any) => {setVersionFilter(newValue)}} presetValue={versionFilter}/>,
        <TextFilterInput label="Firmware name" placeholder="Type to filter by firmware name" updateValue={(newValue: any) => {setFirmwareNameFilter(newValue)}} presetValue={firmwareNameFilter}/>,
        <DropdownFilterInput label="Device Type" selectedOption={deviceTypeFilter +""} onSelect={(newValue: any) => { setDeviceTypeFilter(newValue) }} placeholder="Device type" dropdownOptions={deviceTypes.map(mech => { return { id: mech.name, label: mech.name } })}/>,
        <DropdownFilterInput label="Priority" selectedOption={priorityFilter +""} onSelect={(newValue: any) => { setPriorityFilter(newValue) }} placeholder="Priority" dropdownOptions={priorities.map(mech => { return { id: mech.label, label: mech.label } })}/>
    ];

    const filter = () => {
        let baseFirmwareVersions = [...firmwares];
        if (searchText != '') {
            baseFirmwareVersions = baseFirmwareVersions.filter(f => f.firmware_url.includes(searchText));
        }
        if (versionFilter != '') {
            baseFirmwareVersions = baseFirmwareVersions.filter(f => f.version.includes(versionFilter));
        }
        if (firmwareNameFilter != '') {
            baseFirmwareVersions = baseFirmwareVersions.filter(f => f.firmware_url.includes(firmwareNameFilter));
        }
        if (deviceTypeFilter != '-1') {
            baseFirmwareVersions = baseFirmwareVersions.filter(f => f.device_type == deviceTypeFilter);
        }
        if (priorityFilter != '-1') {
            baseFirmwareVersions = baseFirmwareVersions.filter(f => f.priority == priorityFilter);
        }
        filtersContext.setFirmwareFilters({name:firmwareNameFilter, deviceType:deviceTypeFilter, 
            priority: priorityFilter, version: versionFilter})
        setFilteredFirmwares(baseFirmwareVersions);
    }

    const resetFilters = () => {
        setVersionFilter('');
        setFirmwareNameFilter('');
        setDeviceTypeFilter('-1');
        setPriorityFilter('-1');
    }
    
    const deleteFirmware = (fw: Firmware) => {
        return new Promise<void>((resolve, reject) => {
            FirmwareAPIFactory.getFirmwareAPI().deleteFirmware(fw).then(()=>{
                setFirmwares(firmwares.filter(u => u.device_type != fw.device_type || u.version != fw.version));
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    const editFirmware = (fw: Firmware) => {
        return new Promise<void>((resolve, reject) => {
            if (fw.updatePeriod)
                fw.updatePeriod = +fw.updatePeriod;
            FirmwareAPIFactory.getFirmwareAPI().editFirmware(fw).then(()=>{
                let idx = firmwares.findIndex(u => u.device_type == fw.device_type && u.version == fw.version);
                let newFirmware = [...firmwares];
                newFirmware[idx] = fw;
                setFirmwares(newFirmware);
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }
    
    const createFirmware = (fw: Firmware) => {
        return new Promise<void>((resolve, reject) => {
            if (fw.updatePeriod)
                fw.updatePeriod = +fw.updatePeriod;
            FirmwareAPIFactory.getFirmwareAPI().addFirmware(fw).then(()=>{
                setFirmwares([...firmwares, fw]);
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }
    return (
        <DashboardLayout filter={{filter: ExpandableFilterLayout, props:{
            placeholder: 'Search by name', onReset: resetFilters, filterItems: filters, onTextSearchChange: (newValue: string) => {setSearchText(newValue)}}}}
        tableFooter={<AddEditPanel onSubmit={createFirmware} onEdit={editFirmware} onDelete={deleteFirmware} priorities={priorities} deviceTypes={deviceTypes} editMode={editingFirmware ? true : false} firmware={editingFirmware ? editingFirmware : new Firmware((filtersContext.getFirmwareFilters().deviceType && filtersContext.getFirmwareFilters().deviceType !== '-1')?filtersContext.getFirmwareFilters().deviceType:'', '', false, (filtersContext.getFirmwareFilters().priority && filtersContext.getFirmwareFilters().priority !== '-1')?filtersContext.getFirmwareFilters().priority:'', '', undefined)} onClose={() => { setEditingFirmwares(null) }}></AddEditPanel>} title="Firmware versions" 
        
        tableProps={{
            tableHeaders: ['Device type', 'Version', 'Default', 'Priority', 'Update period', 'Firmware'],
            tableFooterInfo: {currentPage: currentPage,
                numberOfEntries: filteredFirmwares.length,
                entriesPerPage: maxEntries,
                pageSelected: (newPage: number) => {setCurrentPage(newPage)}
            }
        }}>
            {filteredFirmwares.slice((currentPage-1) * maxEntries, (currentPage-1) * maxEntries + maxEntries).map(fw => <FirmwareRow key={fw.firmware_url} editToggled={(toggled) => {toggled ? setEditingFirmwares(fw) : setEditingFirmwares(null)}} editingRow={editingFirmware ? editingFirmware.firmware_url == fw.firmware_url : false} firmware={fw}></FirmwareRow>)}
        </DashboardLayout>
    );
};

export default withData(() => Promise.all([
    FirmwareAPIFactory.getFirmwareAPI().getFirmwares(), 
    DevicesAPIFactory.getDevicesAPI().getDeviceTypes(),
    FirmwareAPIFactory.getFirmwareAPI().getFirmwarePriorities()
]))(FirmwaresDashboard);