import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import SubmitButton from '../SubmitButton/SubmitButton';

interface IFileSelector {
    handleFile: (f: File) => void;
    accept?: string;
}

const UploadFileButton: React.FunctionComponent<IFileSelector> = ({handleFile, accept}) => {
    const [fileName, setFileName] = useState<string | null>(null);
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const {t} = useTranslation();
    const handleClick = () => {
        hiddenFileInput?.current?.click();
    };
    const handleChange = (event: any) => {
        setFileName(event.target.files[0].name);
        handleFile(event);
    };
    return (
        <React.Fragment>
            <SubmitButton label={fileName ? fileName : t("Select file")} onClick={handleClick} isSubmit={false}/>
            <input type="file"
            ref={hiddenFileInput}
            accept={accept}
            style={{display: 'none'}}
            onChange={handleChange}
            />
        </React.Fragment>
    )
}

export default UploadFileButton;