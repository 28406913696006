import { createContext } from "react";
import { Firmware } from '@api/firmwares/FirmwareAPI';
import { Tag } from "@api/tags_api/TagsAPI";

interface IUsersFilterContext {
  filters: any;
  setUsersFilters: (filters:any) => void;
  getUsersFilters: () => any;
}

const UsersFilterContext = createContext<IUsersFilterContext>({filters: {}, setUsersFilters: (filters:any) => {}, getUsersFilters: () => {return {}}});

export default UsersFilterContext;