import React, { useEffect, useState } from 'react';
import NavigationLink from './Link/NavigationLink';
import './Navigation.css';
import {FaUsers, FaLaptop, FaList, FaTags, FaLayerGroup, FaSpinner} from 'react-icons/fa';
import UserBadge from '@components/ui/userbadge/UserBadge';
import AuthFactory from '@api/auth/AuthFactory';

const DashboardNavigation: React.FunctionComponent = (() => {
    const [username, setUsername] = useState('');
    useEffect(() => {
        AuthFactory.getAuthAPI().getUsername().then(d => setUsername(d));
    }, []);
    return (
        <div className="NavigationContainer">
            <div>

            </div>
            <div>
                <NavigationLink path="/dashboard/users" label="Users" icon={FaUsers}/>
                <NavigationLink path="/dashboard/devicetypes" label="Device Types" icon={FaLaptop}/>
                <NavigationLink path="/dashboard/devices" label="Devices" icon={FaList}/>
                <NavigationLink path="/dashboard/updates" label="Updates" icon={FaSpinner}/>
                <NavigationLink path="/dashboard/tags" label="Tags" icon={FaTags}/>
                <NavigationLink path="/dashboard/firmwares" label="Firmware Versions" icon={FaLayerGroup}/>
            </div>
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20}}>
                <UserBadge username={username}></UserBadge>
            </div>
        </div>
    )
});

export default DashboardNavigation;