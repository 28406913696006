export const ptTranslations = {
    "Cancel": "Cancelar",
    "Add new": "Adicionar novo",
    "Dropdown filter expects options": "O filtro de dropdown espera opções",
    "Select an entity in the left panel": "Selecione uma opção no painel à esquerda",
    "Firmware Management System": "Sistema de Gestão de Firmware",
    "Name": "Nome",
    "Address": "Endereço",
    "Target firmware version": "Versão de firmware alvo",
    "Device type": "Tipo de dispositivo",
    "Device types": "Tipos de dispositivos",
    "Device Types": "Tipos de dispositivos",
    "Device Type": "Tipo de Dispositivo",
    "Device edited": "Dispositivo alterado", 
    "Failed to edit device": "Alteração de dispositivo falhada",
    "Device deleted": "Dispositivo removido", 
    "Failed to delete device": "Remoção de dispositivo falhada",
    "Device created": "Dispositivo criado",
    "Failed to create device":"Criação de dispositivo falhada. Verifique que não existe um dispositivo com o mesmo Mac e tipo de dispositivo.",
    "Save": "Salvar",
    "Submit": "Submeter",
    "Tags": "Tags",
    "Mac":"Endereço Mac",
    "Firmware Version": "Versão de Firmware",
    "Firmware version": "Versão de firmware",
    "Target Version": "Versão Alvo",
    "No Target Version": "Sem Versão Alvo",
    "Devices": "Dispositivos",
    "Upload csv file": "Enviar ficheiro CSV",
    "Update command sent": "Comando de update enviado",
    "Update command failed": "Falha no envio de comando de update",
    "Update firmware to target versions": "Atualizar firmware para as versões alvo",
    "Available tags": "Tags disponíveis",
    "Notification Method": "Método de Notificação",
    "Download Method": "Método de Download",
    "Notification method": "Método de notificação",
    "Download method": "Método de download",
    "Page load operation failed with error:":"Falha no carregamento de página:",
    "Type to filter by name": "Digite para filtrar por nome",
    "Type to filter by address": "Digite para filtrar por endereço",
    "File uploaded":"Ficheiro enviado",
    "File upload failed:": "Envio de ficheiro falhou:",
    "Priority": "Prioridade",
    "Version": "Versão",
    "Is default": "Predefinida",
    "Firmware name": "Nome do firmware",
    "Update period": "Período da atualização",
    "Upload firmware bin file": "Envio de binário de firmware",
    "Yes": "Sim",
    "No": "Não",
    "Insert tag name": "Insira nome da tag",
    "Tag deleted": "Tag apagada", 
    "Tags deleted": "Tags apagadas", 
    "Failed to delete tag": "Falha a apagar a tag",
    "Failed to delete tags": "Falha a apagar as tags",
    "Tag created": "Tag criada",
    "Failed to create tag":"Falha a criar a tag",
    "Delete selected tags": "Apagar tags selecionadas",
    "Insert username": "Inserir nome de utilizador",
    "Insert user email": "Inserir email de utilizador",
    "Insert password": "Inserir palavra passe",
    "Select permissions": "Selecione permissão",
    "Users": "Utilizadores",
    "Username": "Nome de utilizador",
    "Email": "Email",
    "Permission": "Permissão",
    "Firmware Versions": "Versões de Firmware",
    "New password": "Nova palavra passe",
    "Confirm password": "Confirmar palavra passe",
    "Confirm new password": "Confirmar nova palavra passe",
    "Change password": "Alterar palavra passe",
    "Logout": "Logout",
    "Search by name": "Pesquisar por nome",
    "Filters": "Filtros",
    "Tag name": "Nome da tag",
    "Delete": "Apagar",
    "Device management": "Gestão de Dispositivos",
    "*Required": "*Obrigatório",
    "Period must be numerical": "O período deve ser numérico",
    "Select file": "Selecionar ficheiro",
    "CsvToolTip":`CSV Deve ter um cabeçalho com os seguintes nomes (case insensitive):
    - name              Tipo: string.                                                Obrigatório: sim.   Default: none
    - type              Tipo: string.                                                Obrigatório: sim.   Default: none
    - address           Tipo: string.                                                Obrigatório: não.   Default: none
    - target_version    Tipo: string.                                                Obrigatório: não.   Default: none
    - tags              Tipo: string, lista de tags separada por ponto e vírgula.    Obrigatório: não.   Default: none
    - force_version     Tipo: boolean (false|true|1|0).                              Obrigatório: não.   Default: false
    - trigger_update    Tipo: boolean (false|true|1|0).                              Obrigatório: não.   Default: false`,
    "Updates": "Atualizações",
    "Update Status": "Estado das atualizações",
    "Version Notified": "Versão notificada em"
};