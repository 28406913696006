import { _axios } from "@api/axiosinstance";
import apiPath from "@api/config";
import DeviceTypeAPI, { DeviceType, DownloadMethod, NotificationMechanism } from "./DeviceTypeAPI";

class LiveDeviceTypeAPI implements DeviceTypeAPI {
    getDeviceTypes(): Promise<DeviceType[]> {
        return new Promise<DeviceType[]>((resolve, reject) => {
            _axios.get('device_type').then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve(response.data.map((content: any) => new DeviceType(content.name, content.notification_method, content.download_method, content.data)))
            }).catch(error => reject(error));
        })
    }
    addDeviceType(deviceType: DeviceType): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.post('device_type', deviceType).then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve();
            }).catch(error => reject(error));
        });
    }
    deleteDeviceType(name: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.delete('device_type/' + name).then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve();
            }).catch(error => reject(error));
        })
    }
    editDeviceType(deviceType: DeviceType, oldName: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.put('device_type/' + oldName, deviceType).then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve();
            }).catch(error => reject(error));
        });
    }
    getNotificationMechanisms(): Promise<NotificationMechanism[]> {
        return new Promise<NotificationMechanism[]>((resolve, reject) => {
            _axios.get('notification_methods').then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve(response.data.map((content: any) => new NotificationMechanism(content.id, content.name)))
            }).catch(error => reject(error));
        })
    }
    getDownloadMethods(): Promise<DownloadMethod[]> {
        return new Promise<DownloadMethod[]>((resolve, reject) => {
            _axios.get('download_methods').then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve(response.data.map((content: any) => new DownloadMethod(content.id, content.name)))
            }).catch(error => reject(error));
        })
    }
}

export default LiveDeviceTypeAPI;