import { Firmware } from '@api/firmwares/FirmwareAPI';
import TableRow from '@components/ui/layout/TableLayout/Row/TableRow';
import TextEntry from '@components/ui/layout/TableLayout/Text/TextEntry';
import React, { useState, useEffect } from 'react';
import { FaCloudDownloadAlt, FaCloudUploadAlt, FaPencilAlt } from 'react-icons/fa';
import ToggleButton from '@components/ui/inputs/buttons/ToggleButton/ToggleButton';
import FirmwareAPIFactory from '@api/firmwares/FirmwareAPIFactory';

interface IFirmwareRow {
    firmware: Firmware;
    editingRow: boolean;
    editToggled: (newValue: boolean) => void;
}

const FirmwareRow: React.FunctionComponent<IFirmwareRow> = ({ firmware, editingRow, editToggled }) => {
    const [editing, setEditing] = useState<boolean>(editingRow);
    useEffect(() => { setEditing(editingRow) }, [editingRow]);
    return (
        <TableRow>
            <div>
                <TextEntry label={firmware.device_type}></TextEntry>
            </div>
            <div>
                <TextEntry label={firmware.version}></TextEntry>
            </div>
            <div>
                <TextEntry label={firmware.is_default ? "Yes" : "No"}></TextEntry>
            </div>
            <div>
                <TextEntry label={firmware.priority + ''}></TextEntry>
            </div>
            <div>
                <TextEntry label={firmware.updatePeriod + ' s'}></TextEntry>
            </div>
            <div>
                <TextEntry label={firmware.firmware_url}></TextEntry>
            </div>
            <div>
                <ToggleButton size={48} canToggle={false} backgroundColor='#1E90FF' color='#FFFFFF' active={editing} icon={FaCloudDownloadAlt} onChange={(newValue: boolean) => { FirmwareAPIFactory.getFirmwareAPI().downloadFirmware(firmware.firmware_url) }} />
            </div>
            <div>
                <ToggleButton size={48} backgroundColor='#FFFFFF' active={editing} icon={FaPencilAlt} onChange={(newValue: boolean) => { editToggled(newValue) }} />
            </div>
        </TableRow>);
};

export default FirmwareRow;