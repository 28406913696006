import React, { useContext, useEffect, useState } from 'react';
import DashboardLayout from '@ui/layout/DashboardLayout/DashboardLayout';
import ExpandableFilterLayout from '@components/ui/layout/ExpandableFilterLayout/ExpandableFilterLayout';
import {TextFilterInput} from '@components/ui/inputs/filter/FilterInput';
import { User } from '@api/user/UserAPI';
import UserAPIFactory from '@api/user/UserAPIFactory';
import UserRow from './UserRow';
import AddEditPanel from './AddEditPanel';
import withData, { DataProp } from '@components/wrappers/DataLoader/DataLoader';
import UsersFilterContext from '@context/UsersFilterContext';

const UsersDashboard: React.FunctionComponent<DataProp<User[]>> = ({data}) => {
    const filtersContext = useContext(UsersFilterContext);
    const maxEntries = 8;
    const [users, setUsers] = useState<User[]>(data);
    const [filteredUsers, setFilteredUsers] = useState<User[]>(data);
    const [editingUser, setEditingUser] = useState<User | undefined>(undefined);
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [nameFilter, setNameFilter] = useState<string>((filtersContext.getUsersFilters().name)?filtersContext.getUsersFilters().name:'');
    const [emailFilter, setEmailFilter] = useState<string>((filtersContext.getUsersFilters().email)?filtersContext.getUsersFilters().email:'');
    const [currentPage, setCurrentPage] = useState<number>(1);

    useEffect(() => {
        filter();
    }, [nameFilter, emailFilter, searchFilter, users]);

    useEffect(() => {
        setCurrentPage(1);
    }, [filteredUsers]);

    const filters: React.ReactNode[] = [
        <TextFilterInput label="Username" placeholder="Insert username" updateValue={(newValue: any) => {setNameFilter(newValue)}} presetValue={nameFilter}/>,
        <TextFilterInput label="Email" placeholder="Insert email" updateValue={(newValue: any) => {setEmailFilter(newValue)}} presetValue={emailFilter}/>
    ];
    
    const filter = () => {
        let baseUsrs = [...users];
        if (searchFilter != "") {
            baseUsrs = baseUsrs.filter(u => u.username.toLowerCase().includes(searchFilter.toLowerCase()));
        }
        if (nameFilter != "") {
            baseUsrs = baseUsrs.filter(u => u.username.toLowerCase().includes(nameFilter.toLowerCase()));
        }
        if (emailFilter != "") {
            baseUsrs = baseUsrs.filter(u => u.email.toLowerCase().includes(emailFilter.toLowerCase()));
        }
        filtersContext.setUsersFilters({name:nameFilter, email:emailFilter});
        setFilteredUsers(baseUsrs);
    }

    const resetFilters = () => {
        setNameFilter('');
        setEmailFilter('');
    }

    let tableHeaders: string[] = ["Username", "Email", "Permission"];
    const setUser = (user: User) => {
        setEditingUser(user);
    }
    let tableEntries: React.ReactNode[] = filteredUsers.slice((currentPage-1) * maxEntries, (currentPage-1) * maxEntries + maxEntries).map((user, idx) => (<UserRow key={idx} user={user} editingRow={(editingUser ? editingUser.id == user.id : false)} editToggled={(toggled: boolean)=>{ toggled ? setUser(user) : setEditingUser(undefined);}}/>));

    const deleteUser = (id: string) => {
        return new Promise<void>((resolve, reject) => {
            UserAPIFactory.getUserAPI().deleteUser(id).then(()=>{
                setUsers(users.filter(u => u.id != id));
                resolve()
            }).catch(error => {
                reject(error);
            });
        });
    }

    const editUser = (usr: User) => {
        return new Promise<void>((resolve, reject) => {
            UserAPIFactory.getUserAPI().editUser(usr).then(()=>{
                let idx = users.findIndex(u => u.id == usr.id);
                let newUsers = [...users];
                newUsers[idx] = usr;
                setUsers(newUsers);
                resolve()
            }).catch(error => {
                reject(error);
            });
        });
    }
    
    const createUser = (usr: User) => {
        return new Promise<void>((resolve, reject) => {
            UserAPIFactory.getUserAPI().addUser(usr).then((id)=>{
                usr.id = id;
                setUsers([...users, usr]);
                resolve()
            }).catch(error => {
                reject(error);
            });
        });
    }

    return (
        <DashboardLayout 
            title="Users" 
            filter={{filter: ExpandableFilterLayout, props:{
                placeholder: 'Search by name', onReset: resetFilters, filterItems: filters, onTextSearchChange: (newValue: string) => {setSearchFilter(newValue)}}}} 
            tableProps={{
                tableHeaders: tableHeaders,
                tableFooterInfo: {currentPage: currentPage,
                    numberOfEntries: filteredUsers.length,
                    entriesPerPage: maxEntries,
                    pageSelected: (newPage: number) => {setCurrentPage(newPage)}
                }
            }}
            tableFooter={
                <AddEditPanel onEdit={editUser} onSubmit={createUser} onDelete={deleteUser} editMode={editingUser ? true : false} user={editingUser} onClose={() => {setEditingUser(undefined)}}></AddEditPanel>
            }
            >
                {tableEntries}
            </DashboardLayout>
    );
}

export default withData(UserAPIFactory.getUserAPI().getUsers)(UsersDashboard);