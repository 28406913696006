import { _axios } from "@api/axiosinstance";
import IUpdatesAPI, { DeviceUpdateStatus, UpdateStatus } from "./UpdatesAPI";

class LiveUpdatesAPI implements IUpdatesAPI {
    getUpdateCount(queryParams?: string): Promise<number> {
        return new Promise<number>((resolve, reject) => {
            _axios.get(`updatescount?${queryParams}`).then(response => {
                if (response.status != 200) reject(response.statusText);
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        });
    }
    getDetailedUpdateList(deviceId: string): Promise<DeviceUpdateStatus[]> {
        return new Promise<DeviceUpdateStatus[]>((resolve, reject) => {
            _axios.get(`updates/${deviceId}`).then(response => {
                if (response.status != 200) reject(response.statusText);
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        });
    }
    getUpdateStatusList(): Promise<UpdateStatus[]> {
        return new Promise<UpdateStatus[]>((resolve, reject) => {
            _axios.get('statuses').then(response => {
                if (response.status != 200) reject(response.statusText);
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        });
    }
    getDevicesUpdateInfo(queryParams?: string, pageNum?: number, countPerPage?: number): Promise<DeviceUpdateStatus[]> {
        return new Promise<DeviceUpdateStatus[]>((resolve, reject) => {
            _axios.get(`updates?${queryParams}&pageNum=${pageNum}&countPerPage=${countPerPage}`).then(response => {
                if (response.status != 200) reject(response.statusText);
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        });
    }
}

export default LiveUpdatesAPI;