import { createContext } from "react";
import { Firmware } from '@api/firmwares/FirmwareAPI';
import { Tag } from "@api/tags_api/TagsAPI";

interface IDeviceTypeFilterContext {
  filters: any;
  setDeviceTypeFilters: (filters:any) => void;
  getDeviceTypeFilters: () => any;
}

const DeviceTypeFilterContext = createContext<IDeviceTypeFilterContext>({filters: {}, setDeviceTypeFilters: (filters:any) => {}, getDeviceTypeFilters: () => {return {}}});

export default DeviceTypeFilterContext;