import IUserAPI from "./UserAPI";
import MockUserAPI from "./MockUserAPI";
import LiveUserAPI from "./LiveUserAPI";
import { isDev } from "@api/config";
class UserAPIFactory {
    static mockAPI = new MockUserAPI();
    static liveAPI = new LiveUserAPI();
    static getUserAPI(): IUserAPI {
        if(isDev())  {
            return this.mockAPI;
        }
        return this.liveAPI;
    }
}

export default UserAPIFactory;