import { _axios } from "@api/axiosinstance";
import apiPath from "@api/config";
import TagsAPI, { Tag } from "./TagsAPI";

class LiveTagsAPI implements TagsAPI {
    addTag(tag: Tag): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.post('tag/' + tag.name).then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve();
            }).catch(error => reject(error));
        });
    }
    deleteTag(tag: Tag): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.delete('tag/' + tag.name).then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve();
            }).catch(error => reject(error));
        });
    }
    deleteTags(tags: Tag[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.post('tags/delete', tags).then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve();
            }).catch(error => reject(error));
        });
    }
    editTag(tag: Tag): Promise<void> {
        throw new Error("Method not implemented.");
    }
    getTags(): Promise<Tag[]> {
        return new Promise<Tag[]>((resolve, reject) => {
            _axios.get('tags').then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve(response.data.map((t: any) => new Tag(t.name)));
            }).catch(error => reject(error));
        })
    }

}

export default LiveTagsAPI;