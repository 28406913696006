import IDeviceTypeAPI, { Device, DeviceTypeView, FirmwareView } from './DevicesAPI';

class MockDevicesAPI implements IDeviceTypeAPI {
    getAllDevicesFiltered(queryParams: string): Promise<Device[]> {
        return new Promise<Device[]>((resolve, reject) => {
            setTimeout(() => {
                const devices:Device[] = [];
                for (let i = 0; i < 200; i++) {
                    devices.push(new Device(i, 'Device_' + i, 'Monometer Wifi', '1.0.0', '1.0.1', ['Tag 1', 'Tag 2', 'Tag 3'], false, {address: '127.0.0.1'}, new Date().toUTCString(), new Date().toUTCString(),'Device_' + i));
                };
                resolve(
                    devices
                );
            }, 500);
        });
    }
    requestFirmware(devices: Device[]): Promise<void> {
        return new Promise<void> (resolve => {
            setTimeout(() => {
                resolve();
            }, 500);
        })
    }
    uploadDevicesFile(file: File): Promise<void> {
        return new Promise<void> (resolve => {
            setTimeout(() => {
                resolve();
            }, 500);
        })
    }
    getDeviceCount(queryParams: string): Promise<number> {
        return new Promise<number> (resolve => {
            setTimeout(() => {
                resolve(200);
            }, 500);
        })
    }
    getDevicesFiltered(queryParams: string, pageNum: number, countPerPage: number): Promise<Device[]> {
        return new Promise<Device[]>((resolve, reject) => {
            setTimeout(() => {
                const devices:Device[] = [];
                for (let i = 0; i < 200; i++) {
                    devices.push(new Device(i, 'Device_' + i, 'Monometer Wifi', '1.0.0', '1.0.1', ['Tag 1', 'Tag 2', 'Tag 3'], false, {address: '127.0.0.1'}, new Date().toUTCString(),new Date().toUTCString(),'Device_' + i));
                };
                resolve(
                    devices.slice((pageNum-1) * countPerPage, (pageNum-1) * countPerPage + countPerPage)
                );
            }, 500);
        });
    }
    deleteDevices(deviceIds: string[]): Promise<void> {
        return new Promise<void> (resolve => {
            setTimeout(() => {
                resolve();
            }, 500);
        })
    }
    updateDevices(deviceIds: Device[]): Promise<void> {
        return new Promise<void> (resolve => {
            setTimeout(() => {
                resolve();
            }, 500);
        })
    }
    addTagToDevice(deviceId: string, deviceType: string, tag: string): Promise<void> {
        return new Promise<void> (resolve => {
            setTimeout(() => {
                resolve();
            }, 500);
        })
    }
    removeTagFromDevice(deviceId: string, deviceType: string, tag: string): Promise<void> {
        return new Promise<void> (resolve => {
            setTimeout(() => {
                resolve();
            }, 500);
        })
    }
    getFirmwares(): Promise<FirmwareView[]> {
        return new Promise<FirmwareView[]>(resolve => {
            setTimeout(() => {
                resolve([new FirmwareView('1.0.0'), new FirmwareView('1.0.1')]);
            }, 500);
        });
    }
    getDeviceTypes(): Promise<DeviceTypeView[]> {
        return new Promise<DeviceTypeView[]>(resolve => {
            setTimeout(() => {
                resolve([new DeviceTypeView('Monometer Wifi'), new DeviceTypeView('Monometer Telepathic')]);
            }, 500);
        });
    }
    addDevice(user: Device): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            console.log(JSON.stringify(user));
            setTimeout(() => {
                resolve();
            }, 500);
        });
    }
    deleteDevice(id: string, deviceType: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, 500);
        });
    }
    editDevice(user: Device, oldName: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, 500);
        });
    }
    getDevices(pageNum: number, countPerPage: number): Promise<Device[]> {
        return new Promise<Device[]>((resolve, reject) => {
            setTimeout(() => {
                const devices:Device[] = [];
                for (let i = 0; i < 200; i++) {
                    devices.push(new Device(i, 'Device_' + i, 'DeviceType_1', '1.0.0', '1.0.1', ['Tag 1', 'Tag 2', 'Tag 3'], false, {address: '127.0.0.1'}, new Date().toUTCString(), new Date().toUTCString(),'Device_' + i));
                };
                resolve(
                    devices.slice((pageNum-1) * countPerPage, (pageNum-1) * countPerPage + countPerPage)
                );
            }, 500);
        });
    }

}

export default MockDevicesAPI;