import AuthFactory from '@api/auth/AuthFactory';
import AuthContext from '@context/AuthContext';
import React, { useContext, useState } from 'react';
import { FaEllipsisH, FaUser } from 'react-icons/fa';
import { useHistory } from 'react-router';
import SubmitButton from '../inputs/buttons/SubmitButton/SubmitButton';
import TextInput from '../inputs/text/TextInput/TextInput';
import "./UserBadge.css";

interface IUserBadge {
    username: string;
}

const UserBadge: React.FunctionComponent<IUserBadge> = ({ username }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [changingPasswordOpen, setChangingPasswordOpen] = useState<boolean>(false);
    const [processingLogout, setProcessingLogout] = useState<boolean>(false);
    const [newPassword, setNewPassword] = useState<string>('');
    const [repeatNewPassword, setRepeatNewPassword] = useState<string>('');
    const [changingPassword, setChangingPassword] = useState<boolean>(false);
    const ctx = useContext(AuthContext);
    const history = useHistory();
    const logout = () => {
        setProcessingLogout(true);
        AuthFactory.getAuthAPI().logout().then(() => {
            setProcessingLogout(false);
            setOpen(false);
            ctx.setToken(null);
            history.push('/');
        }).catch((error) => {
            setProcessingLogout(false);
            setOpen(false);
            ctx.setToken(null);
            history.push('/');
        });
    }
    const changePassword = () => {
        setChangingPassword(true);
        AuthFactory.getAuthAPI().changePassword(newPassword).then(() => {
            setChangingPassword(false);
            setChangingPasswordOpen(false);
            setNewPassword('');
            setRepeatNewPassword('');
        }).catch(() => {
            setChangingPassword(false);
            setChangingPasswordOpen(false);
            setNewPassword('');
            setRepeatNewPassword('');
        });
    }
    let classes = ["BadgeOptions"];
    if (open) classes.push('expanded');
    if (changingPasswordOpen) classes.push('passwordchange');
    return (
        <div className="UserBadge">
            <div className={classes.join(' ')}>
                {
                    changingPasswordOpen ? (<div style={{display: 'flex', flexDirection: 'column'}}>
                        <TextInput placeholder="New password" isPassword={true} updateValue={(val) => {setNewPassword(val)}}/>
                        <TextInput placeholder="Confirm password" isPassword={true} updateValue={(val) => {setRepeatNewPassword(val)}}/>
                        <SubmitButton label="Confirm new password" disabled={newPassword != repeatNewPassword || (newPassword || repeatNewPassword) == '' || changingPassword} isLoading={changingPassword} onClick={() => {changePassword()}}></SubmitButton>
                    </div>) : <SubmitButton label="Change password" onClick={()=>{setChangingPasswordOpen(true)}}/>
                }
                <hr/>
                <SubmitButton label="Logout" isDelete={true} onClick={logout} disabled={processingLogout} isLoading={processingLogout}/>
            </div>
            <FaUser size={20}></FaUser>
            <span>{username}</span>
            <div style={{cursor: 'pointer'}}>
                <FaEllipsisH size={20} color="#707070" onClick={() => setOpen(!open)}></FaEllipsisH>
            </div>
        </div>
    )
};

export default UserBadge;