import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CheckboxProps {
    label: string;
    onUpdate: (newValue: boolean) => void;
    disabled?: boolean;
    checked: boolean;
}

const Checkbox: React.FunctionComponent<CheckboxProps> = (({label, onUpdate, disabled = false, checked = false}) => {
    const { t } = useTranslation();
    return (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', color: checked ? '#1E90FF' : '#707070'}}>
            <input type="checkbox" checked={checked} style={{borderRadius: '4px', border: '1px solid #707070', width: 16, height: 16}} disabled={disabled} onChange={(event) => {onUpdate(event.target.checked)}}></input>
            <span style={{fontSize:14}}>{t(label)}</span>
        </div>
    );
});

export default Checkbox;