import React from 'react';
import { useTranslation } from 'react-i18next';

import './Dropdown.css'

export interface IDropdown {
    dropdownOptions: {id: string, label: string}[];
    placeholder: string;
    selectedOption?: string;
    onSelect: (id: string) => void;
}

const Dropdown: React.FunctionComponent<IDropdown> = ({dropdownOptions, selectedOption, onSelect, placeholder}) => {
    const { t } = useTranslation();
    return (
        <select className="Dropdown" value={selectedOption ? selectedOption : "-1"} onChange={(event) => onSelect(event.target.value)}>
            <option value="-1" hidden>{placeholder}</option>
            {dropdownOptions?.map(dropdownOption => <option key={dropdownOption.id} value={dropdownOption.id}>{t(dropdownOption.label)}</option>)}
        </select>
    );
}

export default Dropdown;