import { DeviceTypeView } from '@api/devices/DevicesAPI';
import { Firmware, Priority } from '@api/firmwares/FirmwareAPI';
import FirmwareAPIFactory from '@api/firmwares/FirmwareAPIFactory';
import AddButton from '@components/ui/inputs/buttons/AddButton/AddButton';
import UploadFileButton from '@components/ui/inputs/buttons/UploadFileButton/UploadFileButton';
import FirmwareFilterContext from '@context/FirmwareFilterContext';
import { Field, Form, Formik, useFormikContext } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

interface IAddEditPanel {
    editMode: boolean;
    deviceTypes: DeviceTypeView[];
    firmware?: Firmware;
    priorities: Priority[];
    onClose: () => void;
    onSubmit: (u: Firmware) => Promise<any>;
    onEdit: (u: Firmware) => Promise<any>;
    onDelete: (u: Firmware) => Promise<any>;
}

const AddEditPanel: React.FunctionComponent<IAddEditPanel> =
    ({ firmware, editMode, onClose, deviceTypes, priorities, onSubmit, onEdit, onDelete }) => {
        const filtersContext = useContext(FirmwareFilterContext);
        const [fw, setFirmware] = useState<Firmware>(new Firmware('', '', false, '', '', undefined));
        const [selectedFile, setSelectedFile] = useState<File | null>(null);
        const [open, setOpen] = useState(true);
        const { addToast } = useToasts();
        const { t } = useTranslation();
        const fileUploadHandlerWrapper = (cb: (s: string) => void) => {
            return (e: any) => {
                setSelectedFile(e.target.files[0]);
                cb(e.target.files[0].name);
            }
        }
        useEffect(() => {
            setOpen(editMode);
        }, [editMode]);
        useEffect(() => {
            if (firmware) {
                setFirmware(firmware);
            } else {
                setFirmware(new Firmware((filtersContext.getFirmwareFilters().deviceType && filtersContext.getFirmwareFilters().deviceType !== '-1')?filtersContext.getFirmwareFilters().deviceType:'', '', false, (filtersContext.getFirmwareFilters().priority && filtersContext.getFirmwareFilters().priority !== '-1')?filtersContext.getFirmwareFilters().priority:'', '', undefined));
            }
        }, [firmware]);

        const uploadFile = (firmware_url: string) => {
            if (selectedFile == null) return;
            FirmwareAPIFactory.getFirmwareAPI().uploadFirmware(firmware_url, selectedFile).then(() => {
                addToast(t('File uploaded'), { appearance: 'success', autoDismiss: true });
            }).catch((error) => {
                addToast(t('File upload failed:') + ' ' + error, { appearance: 'error', autoDismiss: true });
            });
        }
        return (
            <Formik initialValues={fw} enableReinitialize onSubmit={async (values, {resetForm}) => { editMode ? await onEdit(values as Firmware).then(() => { onClose(); setOpen(false); uploadFile(values.firmware_url); resetForm(); }) : await onSubmit(values as Firmware).then(() => { onClose(); setOpen(false); uploadFile(values.firmware_url) }); resetForm(); }}>
                {({ errors, touched, isSubmitting, setFieldValue }) => (
                    <Form>
                        <AddButton submitLabel={editMode ? "Save" : "Submit"}
                            onSubmit={() => Promise.resolve()}
                            onEdit={() => onEdit(fw)}
                            onDelete={() => onDelete(fw)}
                            expanded={open}
                            editing={editMode}
                            enabled={!isSubmitting}
                            openClicked={() => setOpen(true)}
                            onClose={() => { onClose(); setOpen(false) }}>

                            {
                                !editMode && (
                                    <>
                                        {errors.device_type && touched.device_type && <span style={{ color: 'red', fontSize: 14 }}>{errors.device_type}</span>}
                                        <Field className={errors.device_type && touched.device_type ? "error" : ""} id="device_type" name="device_type" placeholder={t("Device type")} as="select" validate={(value: any) => value ? undefined : t("*Required")}>
                                            <option value="" disabled>{t("Device Type")}</option>
                                            {deviceTypes.map(deviceType => <option key={deviceType.name} value={deviceType.name}>{deviceType.name}</option>)}
                                        </Field>
                                    </>
                                )
                            }
                            {errors.priority && touched.priority && <span style={{ color: 'red', fontSize: 14 }}>{errors.priority}</span>}
                            <Field className={errors.priority && touched.priority ? "error" : ""} id="priority" name="priority" placeholder={t("Priority")} as="select" validate={(value: any) => value ? undefined : t("*Required")}>
                                <option value="" disabled>{t("Priority")}</option>
                                {priorities.map(priority => <option key={priority.label} value={priority.label}>{priority.label}</option>)}
                            </Field>
                            {
                                !editMode && (
                                    <>
                                        {errors.version && touched.version && <span style={{ color: 'red', fontSize: 14 }}>{errors.version}</span>}
                                        <Field className={errors.version && touched.version ? "error" : ""} id="version" name="version" placeholder={t("Version")} validate={(value: any) => value ? undefined : t("*Required")} />
                                    </>
                                )
                            }
                            <div style={{ marginLeft: 10 }}>
                                <label>
                                    <Field type="checkbox" name="is_default" id="is_default" />
                                    {t("Is default")}
                                </label>
                            </div>
                            <UploadFileButton handleFile={fileUploadHandlerWrapper((v: string) => { setFieldValue("firmware_url", v) })} />
                            {errors.firmware_url && touched.firmware_url && <span style={{ color: 'red', fontSize: 14 }}>{errors.firmware_url}</span>}
                            <Field className={errors.firmware_url && touched.firmware_url ? "error" : ""} id="firmware_url" name="firmware_url" placeholder={t("Firmware name")} validate={(value: any) => value ? undefined : t("*Required")} />
                            {errors.updatePeriod && touched.updatePeriod && <span style={{ color: 'red', fontSize: 14 }}>{errors.updatePeriod}</span>}
                            <Field className={errors.updatePeriod && touched.updatePeriod ? "error" : ""} id="updatePeriod" name="updatePeriod" placeholder={t("Update period")} validate={
                                (value: any) => {
                                    if (!value) return t("*Required");
                                    if (isNaN(+value)) return t("Period must be numerical");
                                }
                            } />
                        </AddButton>
                    </Form>
                )}
            </Formik>
        )
    }

export default AddEditPanel;