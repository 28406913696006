import React from 'react';
import styles from './TextSearch.module.css';
import {FaSearch} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { DebounceInput } from 'react-debounce-input';

export interface TextSearchProps {
    onTextSearchChange: (newValue:string) => void;
    onTextSearchSubmit?: () => void;
    placeholder: string;
    defaultValue?:string;
}

const TextSearch: React.FunctionComponent<TextSearchProps> = ({onTextSearchChange, placeholder, onTextSearchSubmit, defaultValue}) => {
    const {t} = useTranslation();
    return (
        <div className={styles.SearchInput}>
            <div style={{cursor: 'pointer', borderRadius: 100, display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={onTextSearchSubmit}>
                <FaSearch color="black" size={16}/>
            </div>
            <DebounceInput value={defaultValue} placeholder={t(placeholder)} debounceTimeout={500} onChange={(event) => {onTextSearchChange(event.target.value)}}/>
        </div>
    )
}

export default TextSearch;