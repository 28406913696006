import SplitLayout from '@components/ui/layout/SplitLayout';
import React from 'react';
import DashboardNavigation from './Navigation/Navigation';
import DashboardContainer from './Container/Container';

const Dashboard: React.FunctionComponent = (() => {
    return (
        <SplitLayout leftPanel={DashboardNavigation} rightPanel={DashboardContainer}></SplitLayout>
    );
});

export default Dashboard;