export class NotificationMechanism {
    id: number;
    name: string;
    constructor(id: number, name: string) {
        this.id = id;
        this.name = name;
    }
};

export class DownloadMethod {
    id: number;
    name: string;
    constructor(id: number, name: string) {
        this.id = id;
        this.name = name;
    }
};

export class DeviceType {
    name: string;
    notification_method: number;
    download_method: number;
    data: {};
    constructor(name: string, notificationMechanism: number, download_method: number, data: {}) {
        this.name = name;
        this.notification_method = notificationMechanism;
        this.download_method = download_method;
        this.data = data;
    }
}

interface IDeviceTypeAPI {
    getDeviceTypes(): Promise<DeviceType[]>; 
    addDeviceType(user: DeviceType): Promise<void>;
    deleteDeviceType(name: string): Promise<void>;
    editDeviceType(user: DeviceType, oldName: string): Promise<void>;
    getNotificationMechanisms(): Promise<NotificationMechanism[]>;
    getDownloadMethods(): Promise<DownloadMethod[]>;
}

export default IDeviceTypeAPI;