import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import './Container.css';
import Default from './Default/Default';
import DevicesDashboard from './Devices/Devices';
import DeviceTypesDashboard from './DeviceTypes/DeviceTypes';
import FirmwaresDashboard from './Firmwares/Firmwares';
import UpdatesDashboard from './Updates/Updates';
import Tags from './Tags/Tags';
import Users from './Users/Users';


const DashboardContainer: React.FunctionComponent = (() => {
    let { path } = useRouteMatch();
    return (
        <div style={{background: 'white', width: '100%', height: '100%'}}>
            <Switch>
                <Route exact path={path} component={Default}/>
                <Route path={`${path}/users`} component={Users}/>
                <Route path={`${path}/devicetypes`} component={DeviceTypesDashboard}/>
                <Route path={`${path}/devices`} component={DevicesDashboard}/>
                <Route path={`${path}/tags`} component={Tags}/>
                <Route path={`${path}/firmwares`} component={FirmwaresDashboard}/>
                <Route path={`${path}/updates`} component={UpdatesDashboard}/>
            </Switch>
        </div>
    )
});

export default DashboardContainer;