import AuthFactory from '@api/auth/AuthFactory';
import SubmitButton from '@components/ui/inputs/buttons/SubmitButton/SubmitButton';
import TextInput from '@components/ui/inputs/text/TextInput/TextInput';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';

const ResetPassword: React.FunctionComponent = () => {
    let { resetToken } = useParams<{resetToken: string}>();
    console.log(resetToken);
    let history = useHistory();
    let {addToast} = useToasts();
    const [password, setPassword] = useState<string>('');
    const [repeatPassword, setRepeatPassword] = useState<string>('');
    const [requestPending, setRequestPending] = useState<boolean>(false);
    
    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', backgroundColor: 'rgb(247, 247, 247)'}}>
            <h1>Password reset</h1>
            <TextInput isPassword={true} placeholder="New password" updateValue={(newVal: string) => {setPassword(newVal)}}/>
            <TextInput isPassword={true} placeholder="Repeat new password" updateValue={(newVal: string) => {setRepeatPassword(newVal)}}/>
            <SubmitButton label="Reset Password" isLoading={requestPending} onClick={() => {
                setRequestPending(true);
                AuthFactory.getAuthAPI().passwordReset(resetToken, password).then(() => {
                    setRequestPending(false);
                    history.push('/');
                }).catch(error => {
                    setRequestPending(false);
                    addToast(error, {appearance: 'error', autoDismiss: true});
                });
            }} disabled={password == '' || resetToken == null || repeatPassword == '' || password != repeatPassword || requestPending}/>
        </div>
    );
}

export default ResetPassword;