import React, { useEffect, useState } from 'react';
import { IconType } from 'react-icons';

interface FilterButtonProps {
    active: boolean;
    onChange: (toggled: boolean) => void;
    icon: IconType;
    size: number;
    backgroundColor: string;
    color?: string;
    canToggle?: boolean;
}

const ToggleButton: React.FunctionComponent<FilterButtonProps> = ({active, onChange, icon, backgroundColor, size, color = "#000000", canToggle = true}) => {
    const [toggled, setToggled] = useState<boolean>(active);
    const toggle = (newValue: boolean) => {
        onChange(newValue); 
        setToggled(newValue);
    }
    useEffect(() => {setToggled(active)}, [active]);
    return (
        <div id="toggle-filters-button" style={{backgroundColor: backgroundColor, cursor: 'pointer', width: size, height: size, borderRadius: 100, display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => { toggle(!toggled) }}>
            {icon({color: (toggled && canToggle) ? '#1E90FF' : color, size: 16})}
        </div>
    )
};

export default ToggleButton;