export class Firmware {
    device_type: string;
    version: string;
    is_default: boolean;
    priority: string;
    firmware_url: string;
    updatePeriod: number | undefined;
    constructor(deviceTypeId: string,
        version: string,
        isDefault: boolean,
        priority: string,
        firmwareName: string,
        updatePeriod: number | undefined) {
            this.device_type = deviceTypeId;
            this.version = version;
            this.is_default = isDefault;
            this.priority = priority;
            this.firmware_url = firmwareName;
            this.updatePeriod = updatePeriod;
    }
}

export class Priority {
    label: string;
    constructor(label: string) {
        this.label = label;
    }
}

interface FirmwareAPI {
    getFirmwares(): Promise<Firmware[]>;
    getFirmwarePriorities(): Promise<Priority[]>;
    addFirmware(f: Firmware): Promise<void>;
    deleteFirmware(f: Firmware): Promise<void>;
    editFirmware(f: Firmware): Promise<void>;
    uploadFirmware(filename: string, file: File): Promise<void>;
    downloadFirmware(filename: string): Promise<void>;
}

export default FirmwareAPI;