import React, { useEffect, useState } from 'react';
import { FaBackward, FaChevronLeft, FaChevronRight, FaForward } from 'react-icons/fa';

interface IPaginator {
    pageSelected: (idx: number) => void,
    currentPage: number,
    numberOfEntries: number,
    entriesPerPage: number,
    maxPages?: number
}

const Paginator: React.FunctionComponent<IPaginator> = ({ pageSelected, numberOfEntries, currentPage, entriesPerPage, maxPages = 20 }) => {
    const pageCount = Math.ceil(numberOfEntries / entriesPerPage)
    const footerStyle: any = { padding: 10, borderBottom: '2px solid #EDEDED', cursor: 'pointer', userSelect: "none" };
    const generatePaginator = () => {
        if (numberOfEntries == 0 || pageCount <= 1) return <React.Fragment/>;
        let paginationFooter = [];
        let firstElementEllipsis = currentPage > 1 && pageCount > maxPages;
        let lastElementEllipsis = currentPage < pageCount && pageCount >= maxPages + currentPage;
        paginationFooter.push((<span style={footerStyle} key={-1} onClick={() => { pageSelected(1) }}><FaBackward color='#707070' size={12}></FaBackward></span>))
        paginationFooter.push((<span style={footerStyle} key={0} onClick={() => { if (currentPage - 1 > 0) pageSelected(currentPage - 1) }}><FaChevronLeft color='#707070' size={12}></FaChevronLeft></span>))
        if (firstElementEllipsis) {
            paginationFooter.push(<span style={footerStyle} key={-2}>...</span>);
        }
        const startIndex = firstElementEllipsis ? Math.min(pageCount - maxPages + 1, currentPage) : 1;
        for (let i = startIndex; i <= (pageCount > maxPages ? Math.min(startIndex + maxPages - 1, pageCount) : pageCount); i++) {
            paginationFooter.push((<span style={{ padding: 10, color: i == currentPage ? '#1E90FF' : '#707070', borderBottom: i == currentPage ? '2px solid #1E90FF' : '2px solid #EDEDED', cursor: 'pointer', userSelect: 'none' }} key={i} onClick={() => { pageSelected(i) }}>{i}</span>));
        }
        if (lastElementEllipsis) {
            paginationFooter.push(<span style={footerStyle} key={-3}>...</span>);
        }
        paginationFooter.push((<span style={footerStyle} key={pageCount + 1} onClick={() => { if (currentPage < pageCount) pageSelected(currentPage + 1) }}><FaChevronRight color='#707070' size={12}></FaChevronRight></span>))
        paginationFooter.push((<span style={footerStyle} key={pageCount + 2} onClick={() => { pageSelected(pageCount) }}><FaForward size={12} color='#707070'></FaForward></span>))
        //setFooter(paginationFooter);
        return paginationFooter;
    }
    let footer = generatePaginator();
    return (
        <React.Fragment>
            {footer}
        </React.Fragment>
    );
}

export default Paginator;