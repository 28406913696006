import { Tag } from '@api/tags_api/TagsAPI';
import Dropdown from '@components/ui/inputs/dropdown/Dropdown';
import Paginator from '@components/ui/layout/TableLayout/Paginator/Paginator';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';
import './DeviceTags.css';

export interface IDeviceTags {
    tags: string[];
    allTags: Tag[];
    tagRemoved: (tagId: string) => void;
    tagAdded: (tagId: string) => void;
}

interface IDeviceTagsContainer extends IDeviceTags {
    onClose: () => void;
    onlyView: boolean;
}

interface IDeviceTag {
    tag: string;
    tagRemoved: (tagId: string) => void;
}

interface IDeviceTagInfo {
    tags: string[];
}

const DeviceTag: React.FunctionComponent<IDeviceTag> = ({ tag, tagRemoved }) => {
    return (
        <div key={tag} className="DeviceTag">
            {
                (<div><span>{tag}</span>
                    <FaTimes color={'#707070'} size={10} style={{ marginLeft: '5px', marginRight: '5px' }} onClick={() => { tagRemoved(tag) }} /></div>)
            }
        </div>
    );
}

const DeviceTagInfo: React.FunctionComponent<IDeviceTagInfo> = ({ tags }) => {
    return (
        <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)'}}>
            {tags.map(tag => {
                return (<div key={tag} className="DeviceTag">
                {
                    (<div><span>{tag}</span></div>)
                }
            </div>)
            })}
        </div>
        
    );
}

const DeviceTags: React.FunctionComponent<IDeviceTagsContainer> = ({ tags, onClose, tagRemoved, tagAdded, allTags, onlyView }) => {
    const { t } = useTranslation();
    return (
        <div className="DeviceTags">
            <div style={{ margin: '5px', display: 'flex' }}>
                <FaTimes color={'#707070'} size={10} style={{ marginLeft: 'auto' }} onClick={onClose} />
            </div>
            <div style={{ margin: '15px', display: 'flex', flexDirection: 'column' }}>
                <div className="DeviceTagsHeader">{t("Tags")}</div>
                {onlyView ? 
                <DeviceTagInfo tags={tags}/>
                :
                <DeviceTagsBody allTags={allTags} tagAdded={tagAdded} tagRemoved={tagRemoved} tags={tags}/>
                }
            </div>
        </div>
    )
};

export const DeviceTagsBody: React.FunctionComponent<IDeviceTags> = ({tags, tagRemoved, allTags, tagAdded}) => {
    const [currentPage, setPage] = useState(1);
    const maxEntries = 8;
    return (
        <div className="DeviceTagsBody">
            <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)'}}>
                {tags.slice((currentPage-1) * maxEntries, (currentPage-1) * maxEntries + maxEntries).map(tag => {
                    return (<DeviceTag tagRemoved={tagRemoved} tag={tag} />)
                })}
                </div>
            <hr />
            <div style={{display: 'flex', flexDirection:'row', justifyContent: 'center'}}>
                <Paginator currentPage={currentPage} entriesPerPage={maxEntries} numberOfEntries={tags.length} pageSelected={(page: number)=>{setPage(page)}} />
            </div>
            <Dropdown placeholder="Available tags" dropdownOptions={allTags.filter(tag => tags.find(t => t == tag.name) == undefined).map(tag => { return { id: tag.name, label: tag.name } })} onSelect={(tag: string) => { tagAdded(tag) }}></Dropdown>
        </div>
    )
}

export default DeviceTags;