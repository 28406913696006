import { NotificationMechanism } from '@api/devicetypes/DeviceTypeAPI';
import { DeviceUpdateStatus, UpdateStatus } from '@api/updates/UpdatesAPI';
import TableRow from '@components/ui/layout/TableLayout/Row/TableRow';
import TextEntry from '@components/ui/layout/TableLayout/Text/TextEntry';
import React from 'react';

interface IDetailedUpdateRow {
    status: DeviceUpdateStatus;
    possibleStatuses: UpdateStatus[];
    notificationMechanisms: NotificationMechanism[];
}

const DetailedUpdateRow: React.FunctionComponent<IDetailedUpdateRow> = ({ status, possibleStatuses, notificationMechanisms }) => {
    return (
        <TableRow>
                <div><TextEntry label={status.id}></TextEntry></div>
                <div><TextEntry label={possibleStatuses.find(n => n.id == status.state)?.name || ''}></TextEntry></div>
                <div><TextEntry label={notificationMechanisms.find(n => n.id == status.notification_method)?.name || ''}></TextEntry></div>
                <div><TextEntry label={status.utc_timestamp}></TextEntry></div>
        </TableRow>
    );
}

export default DetailedUpdateRow;