import IUpdatesAPI, { DeviceUpdateStatus, UpdateStatus } from "./UpdatesAPI";

class MockUpdatesAPI implements IUpdatesAPI {
    getUpdateCount(queryParams?: string): Promise<number> {
        return new Promise<number>(resolve => {
            setTimeout(() => {
                resolve(200);
            }, 500);
        });
    }
    getDetailedUpdateList(deviceId: string): Promise<DeviceUpdateStatus[]> {
        return new Promise<DeviceUpdateStatus[]>(resolve => {
            setTimeout(() => {
                resolve([new DeviceUpdateStatus("1561323165", 1, 1, 'placholder_date')]);
            }, 500);
        });
    }
    getUpdateStatusList(): Promise<UpdateStatus[]> {
        return new Promise<UpdateStatus[]>(resolve => {
            setTimeout(() => {
                resolve([new UpdateStatus(1, 'In progress'), new UpdateStatus(2, 'Error'), new UpdateStatus(3, 'Finished')]);
            }, 500);
        });
    }
    getDevicesUpdateInfo(queryParams?: string, pageNum?: number, countPerPage?: number): Promise<DeviceUpdateStatus[]> {
        return new Promise<DeviceUpdateStatus[]>(resolve => {
            setTimeout(() => {
                const devices: DeviceUpdateStatus[] = [];
                for (let i = 0; i < 200; i++) {
                    devices.push(new DeviceUpdateStatus("1561323165", 1, 1, 'placholder_date'));
                };
                if (pageNum && countPerPage) {
                    resolve(
                        devices.slice((pageNum - 1) * countPerPage, (pageNum - 1) * countPerPage + countPerPage)
                    );
                } else {
                    resolve(devices);
                }
            }, 500);
        });
    }
}

export default MockUpdatesAPI;