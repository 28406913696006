import IAuthAPI from './AuthAPI';

class MockAuthAPI implements IAuthAPI {
    requestPasswordReset(email: string): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 5000);
        });
    }
    passwordReset(passwordResetToken: string, newPassword: string): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 5000);
        });
    }
    changePassword(password: string): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 5000);
        });
    }
    private username: string = 'user';
    getUsername(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            setTimeout(() => {
                resolve(this.username);
            }, 1000);
        });
    }
    login(username: string, password: string, rememberMe: boolean): Promise<string> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if(password === "password") {
                    this.username = username;
                    resolve("sampleAuthToken");
                } else {
                    reject('Invalid password');
                }
            }, 1000);
        });
    }
    logout(): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 1000);
        });
    }

}

export default MockAuthAPI;