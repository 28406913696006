import { DeviceType, DownloadMethod, NotificationMechanism } from '@api/devicetypes/DeviceTypeAPI';
import AddButton from '@components/ui/inputs/buttons/AddButton/AddButton';
import Dropdown from '@components/ui/inputs/dropdown/Dropdown';
import TextInput from '@components/ui/inputs/text/TextInput/TextInput';
import AuthContext from '@context/AuthContext';
import DeviceTypeFilterContext from '@context/DeviceTypeFilterContext';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IAddEditPanel {
    editMode: boolean;
    notificationMechanisms: NotificationMechanism[];
    downloadMethods: DownloadMethod[];
    deviceType?: DeviceType;
    onClose: () => void;
    onSubmit: (u: DeviceType) => Promise<any>;
    onEdit: (u: DeviceType, name: string) => Promise<any>;
    onDelete: (id: string) => Promise<any>;
}

const AddEditPanel: React.FunctionComponent<IAddEditPanel> =
    ({ deviceType, editMode, onClose, notificationMechanisms, downloadMethods, onSubmit, onEdit, onDelete }) => {
        const filtersContext = useContext(DeviceTypeFilterContext);
        const [devType, setDeviceType] = useState<DeviceType>(new DeviceType('', 
        notificationMechanisms.length == 1 ? notificationMechanisms[0].id : -1, 
        downloadMethods.length == 1 ? downloadMethods[0].id : -1, {}));
        const [prevDevTypeName, setPrevDevTypeName] = useState<string>('');
        const [open, setOpen] = useState(false);
        const { t } = useTranslation();
        useEffect(() => {
            setOpen(editMode);
        }, [editMode]);
        useEffect(()=> {
            if(!open) {
                setDeviceType(new DeviceType('', 
                (filtersContext.getDeviceTypeFilters().notificationMethod)?parseInt(filtersContext.getDeviceTypeFilters().notificationMethod):(notificationMechanisms.length == 1 ? notificationMechanisms[0].id : -1), 
                (filtersContext.getDeviceTypeFilters().downloadMethod)?parseInt(filtersContext.getDeviceTypeFilters().downloadMethod):(downloadMethods.length == 1 ? downloadMethods[0].id : -1), {}));
            }
        }, [open])
        useEffect(() => {
            console.log('UE deviceType ', filtersContext.getDeviceTypeFilters());
            if (deviceType) {
                setDeviceType(deviceType);
                setPrevDevTypeName(deviceType.name);
            } else {
                console.log('UE deviceType not ', filtersContext.getDeviceTypeFilters());
                setDeviceType(new DeviceType('', 
                (filtersContext.getDeviceTypeFilters().notificationMethod)?parseInt(filtersContext.getDeviceTypeFilters().notificationMethod):(notificationMechanisms.length == 1 ? notificationMechanisms[0].id : -1), 
                (filtersContext.getDeviceTypeFilters().downloadMethod)?parseInt(filtersContext.getDeviceTypeFilters().downloadMethod):(downloadMethods.length == 1 ? downloadMethods[0].id : -1), {}));
            }
        }, [deviceType]);
        return (
            <Formik initialValues={devType} enableReinitialize onSubmit={async (values, { resetForm }) => { editMode ? await onEdit(values as DeviceType, prevDevTypeName).then(() => { onClose(); setOpen(false); resetForm(); }) : await onSubmit(values as DeviceType).then(() => { onClose(); setOpen(false); resetForm(); }); }}>
                {({ errors, touched, isSubmitting }) => (
                    <Form>
                        <AddButton submitLabel={editMode ? "Save" : "Submit"}
                            onSubmit={() => onSubmit(devType)}
                            onEdit={() => onEdit(devType, prevDevTypeName)}
                            onDelete={() => onDelete(devType.name)}
                            expanded={open}
                            editing={editMode}
                            openClicked={() => setOpen(true)}
                            enabled={!isSubmitting}
                            onClose={() => { onClose(); setOpen(false) }}>
                            {errors.name && touched.name && <span style={{ color: 'red', fontSize: 14 }}>{errors.name}</span>}
                            <Field className={errors.name && touched.name ? "error" : ""} id="name" name="name" placeholder={t("Name")} validate={(value: any) => value ? undefined : t("*Required")} />
                            {notificationMechanisms.length > 1 &&
                                (<>{errors.notification_method && touched.notification_method && <span style={{ color: 'red', fontSize: 14 }}>{errors.notification_method}</span>}
                                    <Field className={errors.notification_method && touched.notification_method ? "error" : ""} id="notification_method" name="notification_method" placeholder={t("Notification Method")} as="select" validate={(value: any) => (value && value != "-1") ? undefined : t("*Required")}>
                                        <option value="-1" disabled>{t("Notification method")}</option>
                                        {notificationMechanisms.map(notificationMechanisms => <option key={notificationMechanisms.id} value={+notificationMechanisms.id}>{notificationMechanisms.name}</option>)}
                                    </Field></>)
                            }
                            {downloadMethods.length > 1 &&
                                (<>
                                    {errors.download_method && touched.download_method && <span style={{ color: 'red', fontSize: 14 }}>{errors.download_method}</span>}
                                    <Field className={errors.download_method && touched.download_method ? "error" : ""} id="download_method" name="download_method" placeholder={t("Download Method")} as="select" validate={(value: any) => (value && value != "-1") ? undefined : t("*Required")}>
                                        <option value="-1" disabled>{t("Download method")}</option>
                                        {downloadMethods.map(notificationMechanisms => <option key={notificationMechanisms.id} value={+notificationMechanisms.id}>{notificationMechanisms.name}</option>)}
                                    </Field>
                                </>)
                            }
                        </AddButton>
                    </Form>
                )}
            </Formik>
        )
    }

export default AddEditPanel;