import { Tag } from '@api/tags_api/TagsAPI';
import TagsAPIFactory from '@api/tags_api/TagsAPIFactory';
import AddButton from '@components/ui/inputs/buttons/AddButton/AddButton';
import TextInput from '@components/ui/inputs/text/TextInput/TextInput';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IAddEditPanel {
    editMode: boolean;
    tag?: Tag;
    onClose: () => void;
    onSubmit: (t: Tag) => Promise<void>;
}

const AddEditPanel: React.FunctionComponent<IAddEditPanel> =
    ({ tag, editMode, onClose, onSubmit }) => {
        const [t, setTag] = useState<Tag>(new Tag(''));
        const [open, setOpen] = useState(false);
        const {t: tr} = useTranslation();
        useEffect(() => {
            setOpen(editMode);
        }, [editMode]);
        useEffect(() => {
            if (tag) {
                setTag(tag);
            } else {
                setTag(new Tag(''));
            }
        }, [tag]);
        return (
            <Formik initialValues={t} enableReinitialize onSubmit={async (values, {resetForm}) => { await onSubmit(values as Tag).then(() => {onClose(); setOpen(false); resetForm();}); }}>
                {({ errors, touched, isSubmitting }) => (
                    <Form>
                        <AddButton submitLabel={editMode ? "Save" : "Submit"}
                            onSubmit={() => { return onSubmit(t) }}
                            onEdit={() => { return TagsAPIFactory.getTagsAPI().editTag(t) }}
                            onDelete={() => { return TagsAPIFactory.getTagsAPI().deleteTag(t) }}
                            expanded={open}
                            editing={editMode}
                            openClicked={() => setOpen(true)}
                            enabled={!isSubmitting}
                            onClose={() => { onClose(); setOpen(false) }}>
                            {errors.name && touched.name && <span style={{color: 'red', fontSize: 14}}>{errors.name}</span>}
                            <Field className={errors.name && touched.name ? "error" : ""} name="name" id="name" placeholder={tr("Tag name")} validate={(value: any) => value ? undefined : tr("*Required")}/>
                        </AddButton>
                    </Form>)}
            </Formik>
        )
    }

export default AddEditPanel;