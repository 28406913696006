import IAuthAPI from './AuthAPI';
import apiPath from '../config';
import { _axios } from '@api/axiosinstance';

class LiveAuthAPI implements IAuthAPI {
    requestPasswordReset(email: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.post('passwordResetRequest', email).then((response)=>{
                if (response.status != 200) reject(response.statusText);
                resolve();
            }).catch(error => reject(error));
        });
    }
    passwordReset(passwordResetToken: string, newPassword: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.post('passwordReset', {resetToken: passwordResetToken, newPassword: newPassword}).then((response)=>{
                if (response.status != 200) reject(response.statusText);
                resolve();
            }).catch(error => reject(error));
        });
    }
    login(username: string, password: string, rememberMe: boolean): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            fetch(apiPath + 'user/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username: username, password: password, rememberMe: rememberMe })
            }).then(response => {
                if (!response.ok) reject(response.statusText);
                response.text().then(content => {
                    resolve(content)
                }).catch(error => reject(error));
            }).catch(error => reject(error));
        })

    }
    getUsername(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            _axios.get("username").then(response => resolve(response.data)).catch(error => reject(error));
        });
    }
    changePassword(password: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.post("user/new_password", password).then(()=>resolve()).catch(error => reject(error));
        });
    }
    logout(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.post('user/logout').then(()=>{resolve()}).catch(error => {reject(error)});
        })
    }

}

export default LiveAuthAPI;
