import IUserAPI, { Permission, User } from './UserAPI';

class MockUserAPI implements IUserAPI {
    getPossiblePermissions(): Promise<Permission[]> {
        return new Promise<Permission[]>((resolve) => {
            setTimeout(() => {
                resolve([new Permission("1", "all"), new Permission("2", "device")]);
            }, 500);
        })
    }
    addUser(user: User): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            console.log(`Adding user ${JSON.stringify(user)}`);
            setTimeout(() => {
                resolve("1");
            }, 500);
        });
    }
    deleteUser(id: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, 500);
        });
    }
    editUser(user: User): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            console.log(`Editing user ${JSON.stringify(user)}`);
            setTimeout(() => {
                resolve();
            }, 500);
        });
    }
    getUsers(): Promise<User[]> {
        return new Promise<User[]>((resolve, reject) => {
            setTimeout(() => {
                console.log('Users called');
                const users:User[] = [];
                for (let i = 0; i < 20; i++) {
                    users.push(new User(i + '', 'User ' + i, 'User_' + i + '@domain.com', 'password', new Permission('1', 'all')));
                };
                resolve(
                    users
                );
            }, 5000);
        });
    }

}

export default MockUserAPI;