import IAuthAPI from "./AuthAPI";
import MockAuthAPI from "./MockAuthAPI";
import {isDev} from '../config';
import LiveAuthAPI from "./LiveAuthAPI";

class AuthFactory {
    static mockAPI = new MockAuthAPI();
    static liveAPI = new LiveAuthAPI();
    static getAuthAPI(): IAuthAPI {
        if (isDev())
            return this.mockAPI;
        return this.liveAPI;
    }
}

export default AuthFactory;