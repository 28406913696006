import React from 'react';
import SplitLayout from '@ui/layout/SplitLayout';
import LoginInput from './LoginInput';
import LoginSplash from './LoginSplash';

const LoginPage : React.FunctionComponent = () => {
    return (
        <SplitLayout leftPanel={LoginInput} rightPanel={LoginSplash}></SplitLayout>
    );
};

export default LoginPage;