import SubmitButton from '@components/ui/inputs/buttons/SubmitButton/SubmitButton';
import React, { ComponentType, useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
export interface DataProp<T> {
    data: T
}
function withData<T>(dataloader: () => Promise<T>): <P extends DataProp<T>>(Component: ComponentType<P>) => React.FunctionComponent<P & DataProp<T>> {
    return <P extends DataProp<T>>(Component: ComponentType<P>) => (props: P & DataProp<T>) => {
        let [data, setData] = useState<T | null>(null);
        let [error, setError] = useState(false);
        let loadData = () => {
            setError(false);
            dataloader()
                .then(r => setData(r))
                .catch((error) => {
                    setError(true);
                });
        }
        useEffect(() => {
            loadData();
        }, []);
        return (
            <React.Fragment>
                { data != null ?
                    <Component {...props as P} data={data} /> :
                    error ?
                        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <span>An error ocurred while processing your request</span>
                            <SubmitButton label="Retry" onClick={loadData} disabled={data != null} isLoading={data != null} />
                        </div>
                        :
                        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <PulseLoader color="#1E90FF" size={18} />
                        </div>
                }
            </React.Fragment>
        );
    };
}

export default withData;