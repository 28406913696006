import React from 'react';
import { useTranslation } from 'react-i18next';
import './Default.css';

const DefaultDashboard: React.FunctionComponent = (() => {
    const { t } = useTranslation();
    return (<div className="DashboardContainer">
        <span className="SubTitle">{t("Select an entity in the left panel")}</span>
        <span className="Title">{t("Firmware Management System")}</span>
        <span className="Dot"></span>
    </div>);
});

export default DefaultDashboard;