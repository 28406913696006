import { Permission, User } from '@api/user/UserAPI';
import UserAPIFactory from '@api/user/UserAPIFactory';
import AddButton from '@components/ui/inputs/buttons/AddButton/AddButton';
import Checkbox from '@components/ui/inputs/checkboxes/Checkbox';
import TextInput from '@components/ui/inputs/text/TextInput/TextInput';
import AuthContext from '@context/AuthContext';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PulseLoader } from 'react-spinners';
import { useToasts } from 'react-toast-notifications';

interface IAddEditPanel {
    editMode: boolean;
    user?: User;
    onClose: () => void;
    onSubmit: (u: User) => Promise<any>;
    onEdit: (u: User) => Promise<any>;
    onDelete: (id: string) => Promise<any>;
}

const AddEditPanel: React.FunctionComponent<IAddEditPanel> = ({ user, editMode, onClose, onSubmit, onEdit, onDelete }) => {
    const [usr, setUser] = useState<User>(new User('', '', '', '', new Permission('', '')));
    const [possiblePermissions, setPossiblePermissions] = useState<Permission[]>([]);
    const [loadingPermissions, setLoadingPermissions] = useState<boolean>(true);
    const [open, setOpen] = useState(false);
    const { addToast } = useToasts();
    const { t } = useTranslation();
    useEffect(() => {
        if (loadingPermissions) {
            UserAPIFactory.getUserAPI().getPossiblePermissions().then((permissions) => {
                setPossiblePermissions(permissions);
                setLoadingPermissions(false);
            }).catch(error => {
                addToast(t("Page load operation failed with error:") + ' ' + error, { appearance: 'error', autoDismiss: true })
            });
        }
    }, [loadingPermissions]);
    useEffect(() => {
        setOpen(editMode);
    }, [editMode]);
    useEffect(() => {
        if (user) {
            setUser(user);
        } else {
            setUser(new User('', '', '', '', new Permission('', '')));
        }
    }, [user]);

    return (
        <Formik initialValues={usr} enableReinitialize 
        onSubmit={async (values, {resetForm}) => { 
            let submitUsr = values as User;
            submitUsr.permission.label = possiblePermissions.find(p => p.id == submitUsr.permission.id)?.label ?? '';
            editMode ? 
                await onEdit(submitUsr).then(() => { onClose(); setOpen(false); resetForm(); }) 
                : 
                await onSubmit(submitUsr).then(() => { onClose(); setOpen(false); resetForm(); });
            }}>
            {({ errors, touched, isSubmitting }) => (
                <Form>
                    <AddButton submitLabel={editMode ? "Save" : "Submit"}
                        onSubmit={() => onSubmit(usr)}
                        onEdit={() => onEdit(usr)}
                        onDelete={() => onDelete(usr.id)}
                        expanded={open}
                        onClose={() => { onClose(); setOpen(false) }}
                        openClicked={() => setOpen(true)}
                        editing={editMode}
                        enabled={!isSubmitting}
                    >
                        {errors.username && touched.username && <span style={{color: 'red', fontSize: 14}}>{errors.username}</span>}
                        <Field className={errors.username && touched.username ? "error" : ""} name="username" id="username" placeholder={t("Username")} validate={(value: any) => value ? undefined : t("*Required")}/>
                        
                        {errors.email && touched.email && <span style={{color: 'red', fontSize: 14}}>{errors.email}</span>}
                        <Field className={errors.email && touched.email ? "error" : ""} name="email" id="email" placeholder={t("Email")} validate={(value: any) => value ? undefined : t("*Required")}/>
                        
                        {errors.password && touched.password && <span style={{color: 'red', fontSize: 14}}>{errors.password}</span>}
                        <Field type="password" className={errors.password && touched.password ? "error" : ""} name="password" id="email" placeholder={t("Insert password")} validate={(value: any) => value ? undefined : editMode ? undefined: t("*Required")}/>
                        
                        <span>{t('Select permissions')}</span>
                        <hr style={{ width: '100%', opacity: 0.5 }} />
                        {
                            loadingPermissions &&
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <PulseLoader color="#1E90FF" size={8} />
                            </div>
                        }
                        {
                            !loadingPermissions &&
                            (
                                <>
                                {errors.permission?.id && touched.permission?.id && <span style={{color: 'red', fontSize: 14}}>{errors.permission?.id}</span>}
                                {possiblePermissions.map((permission) => (
                                    <label key={permission.id}><Field type="radio" name="permission.id" value={permission.id}  validate={(value: any) => value ? undefined : t("*Required")}/>{permission.label}</label>
                                    ))}
                                </>
                            )
                        }
                    </AddButton>
                </Form>)}
        </Formik>
    )
}

export default AddEditPanel;