import { Device } from '@api/devices/DevicesAPI';
import TableRow from '@components/ui/layout/TableLayout/Row/TableRow';
import TextEntry from '@components/ui/layout/TableLayout/Text/TextEntry';
import React, { useState, useEffect } from 'react';
import { FaEllipsisH, FaPencilAlt, FaTag } from 'react-icons/fa';
import ToggleButton from '@components/ui/inputs/buttons/ToggleButton/ToggleButton';
import DeviceTags from './DeviceTags';
import { Tag } from '@api/tags_api/TagsAPI';
import Checkbox from '@components/ui/inputs/checkboxes/Checkbox';

interface IDeviceRow {
    device: Device;
    editingRow: boolean;
    tags: Tag[];
    bottomHalf: boolean;
    editToggled: (newValue: boolean) => void;
    deviceTagRemoved: (tagRemoved: string) => void;
    deviceTagAdded: (tagAdded: string) => void;
    deviceSelected: (newVal: boolean) => void;
    showTagsToggled: (shown: boolean) => void;
    selected: boolean;
    showTags: boolean;
}

const DeviceTypeRow: React.FunctionComponent<IDeviceRow> = ({ bottomHalf, device, editingRow, editToggled, deviceTagRemoved, tags, deviceTagAdded, deviceSelected, selected, showTagsToggled, showTags }) => {
    const [editing, setEditing] = useState<boolean>(editingRow);
    useEffect(() => { setEditing(editingRow) }, [editingRow]);
    let popOverStyle:any = { position: 'absolute', zIndex: 1000};
    const tagsStringTooLong = device.tags.join(',').length > 15;
    let deviceTagsString = device.tags.join(',');
    if (tagsStringTooLong) {
        let truncatedTags = deviceTagsString.substring(0, 15).split(',');
        if (truncatedTags.length === 1) {
            deviceTagsString = truncatedTags[0];
        } else {
            deviceTagsString = truncatedTags.slice(0,truncatedTags.length-1).join(',');
        }
    }

    if(bottomHalf) {
        popOverStyle.bottom = 65
    }
    return (
        <TableRow>
            <div>
                <TextEntry label={device.name}></TextEntry>
            </div>
            <div>
                <TextEntry label={device.display_name}></TextEntry>
            </div>
            <div>
                <TextEntry label={device.data.address}></TextEntry>
            </div>
            <div style={{position:'relative', width:'150px'}}>
                <div style={{display:'flex', alignItems:'center'}}>
                    {deviceTagsString}
                    {tagsStringTooLong &&
                        <ToggleButton size={48} backgroundColor='#FFFFFF' active={showTags} icon={FaEllipsisH} onChange={(newValue: boolean) => { showTagsToggled(newValue) }} />

                    }
                </div>
                {
                    showTags &&
                    <div style={popOverStyle}>
                            <DeviceTags onlyView={true} allTags={tags} tagRemoved={deviceTagRemoved} tagAdded={deviceTagAdded} tags={device.tags} onClose={() => { showTagsToggled(false) }}></DeviceTags>
                    </div>
                }
            </div>
            <div>
                <TextEntry label={device.device_type}></TextEntry>
            </div>
            <div>
                <TextEntry label={device.fwVersion}></TextEntry>
            </div>
            <div>
                <TextEntry label={device.fwVersionTime}></TextEntry>
            </div>
            <div>
                <TextEntry label={device.lastComm}></TextEntry>
            </div>
            <div>
                <TextEntry label={device.targetVersion}></TextEntry>
            </div>
            <div>
                <input type='checkbox' checked={device.force_version} disabled></input>
            </div>
            <div>
                <ToggleButton size={48} backgroundColor='#FFFFFF' active={editing} icon={FaPencilAlt} onChange={(newValue: boolean) => { editToggled(newValue) }} />
            </div>
            <div>
                <Checkbox onUpdate={deviceSelected} checked={selected} label=""></Checkbox>
            </div>
        </TableRow>
    );
};

export default DeviceTypeRow;