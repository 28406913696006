import React, { ComponentType } from 'react'
import { useTranslation } from 'react-i18next';
import classes from './ToolTip.module.css';

export function withTooltip(tooltip: string): (Component: ComponentType<any>) => React.FunctionComponent<any> {
    return (Component: ComponentType<any>) => (props: any) => {
        const {t} = useTranslation();
        return (
        <> 
            <div className={classes.tooltip}>
                <pre className={classes.tooltiptext}>{t(tooltip)}</pre>
                <Component {...props}/>
            </div>
        </>)
    }
}