export class DeviceUpdateStatus{
    id: string;
    state: number;
    notification_method: number;
    utc_timestamp: string;
    constructor(id: string, state: number, notification_method: number, utc_timestamp: string) {
        this.id = id;
        this.state = state;
        this.notification_method = notification_method;
        this.utc_timestamp = utc_timestamp;
    }
}

export class UpdateStatus {
    id: number;
    name: string;
    constructor(id: number, name: string) {
        this.id = id;
        this.name = name;
    }
}

interface IUpdatesAPI {
    getDevicesUpdateInfo(queryParams?: string, pageNum?: number, countPerPage?: number): Promise<DeviceUpdateStatus[]>;
    getUpdateStatusList(): Promise<UpdateStatus[]>;
    getDetailedUpdateList(deviceId: string): Promise<DeviceUpdateStatus[]>;
    getUpdateCount(queryParams?: string): Promise<number>;
}

export default IUpdatesAPI;