import React from 'react';
import './DashboardLayout.css';
import { FilterLayoutProps } from '@ui/layout/ExpandableFilterLayout/ExpandableFilterLayout';
import TableLayout, { ITable } from '@ui/layout/TableLayout/TableLayout';
import { PulseLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';

interface DashboardLayoutProps {
    title: string;
    tableFooter: React.ReactNode;
    filter?: FilterProps;
    empty?: boolean;
    tableProps: ITable;
};

interface FilterProps {
    filter: React.FunctionComponent<FilterLayoutProps>;
    props: FilterLayoutProps;
}

const DashboardLayout: React.FunctionComponent<DashboardLayoutProps> = ({ title, filter, children, tableFooter, empty = false, tableProps }) => {
    const { t } = useTranslation();
    return (
        <div className="DashboardLayoutContainer">
            <div className="HeaderContainer">
                <span className="Header">{t(title)}</span>
                {filter && (
                    <filter.filter
                        filterItems={filter.props.filterItems}
                        onTextSearchChange={filter.props.onTextSearchChange}
                        onSubmit={filter.props.onSubmit}
                        onTextSearchSubmit={filter.props.onTextSearchSubmit}
                        placeholder={filter.props.placeholder}
                        onReset={filter.props.onReset}
                    ></filter.filter>
                )}
            </div>
            <div className="TableContainer">
                {
                    empty ?
                        children
                        :
                        <TableLayout tableHeaders={tableProps.tableHeaders} selectAll={tableProps.selectAll} tableFooterInfo={tableProps.tableFooterInfo}>
                            {children}
                        </TableLayout>
                }
                {tableFooter}
            </div>
        </div>
    )

}

export default DashboardLayout;