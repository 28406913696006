import ITagsAPI from "./TagsAPI";
import MockTagsAPI from "./MockTagsAPI";
import { isDev } from "@api/config";
import LiveTagsAPI from "./LiveTagsAPI";
class TagsAPIFactory {
    static mockAPI = new MockTagsAPI();
    static liveAPI = new LiveTagsAPI();
    static getTagsAPI(): ITagsAPI {
        if (isDev()) {
        return this.mockAPI;
        }
        return this.liveAPI;
    }
}

export default TagsAPIFactory;