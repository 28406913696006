export class Permission {
    id: string;
    label: string;
    constructor(id: string, label: string) {
        this.label = label;
        this.id = id;
    }
};

export class User {
    id: string;
    username: string;
    email: string;
    password: string;
    permission: Permission;
    constructor(id: string, username: string, email:string, password: string, permission:Permission) {
        this.id = id;
        this.username = username;
        this.email = email;
        this.password = password;
        this.permission = permission;
    }
}

interface IUserAPI {
    getUsers(): Promise<User[]>;
    getPossiblePermissions(): Promise<Permission[]>;
    addUser(user: User): Promise<string>;
    deleteUser(id: string): Promise<void>;
    editUser(user: User): Promise<void>;
}

export default IUserAPI;