import { Tag } from '@api/tags_api/TagsAPI';
import DeviceTags, { DeviceTagsBody, IDeviceTags } from '@components/ui/pages/dashboard/Container/Devices/DeviceTags';
import React, { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useTranslation } from 'react-i18next';
import Dropdown, { IDropdown } from '../dropdown/Dropdown';
import { TextInputProps } from '../text/TextInput/TextInput';
import './FilterInput.css'
export enum FilterType { Text, Dropdown, Tags }

export interface FilterProps {
    label: string;
}

const FilterInputTemplate: React.FunctionComponent<FilterProps> = ({label, children}) => {
    const { t } = useTranslation();
    return (
        <div className="FilterInputContainer">
            <span className="Label">{t(label)}</span>
            {children}
        </div>
    )
}

export const TextFilterInput: React.FunctionComponent<TextInputProps & FilterProps> = ({ updateValue, label, placeholder, presetValue }) => {
    return (
        <FilterInputTemplate label={label}>
            <DebounceInput debounceTimeout={500}  onChange={(e: any) => {updateValue(e.target.value)}} placeholder={placeholder} value={presetValue} presetValue={presetValue} />
        </FilterInputTemplate>
    );
}

export const DropdownFilterInput: React.FunctionComponent<IDropdown & FilterProps> = ({ onSelect, dropdownOptions, label, placeholder, selectedOption }) => {
    return (
        <FilterInputTemplate label={label}>
            <Dropdown dropdownOptions={dropdownOptions} placeholder={placeholder} selectedOption={selectedOption} onSelect={onSelect}></Dropdown>
        </FilterInputTemplate>
    );
}

interface ITagFilter extends FilterProps {
    allTags: Tag[];
    tags: Tag[];
    onTagFilterChanged: (tags: Tag[]) => void;
}

export const TagFilterInput: React.FunctionComponent<ITagFilter> = ({ label, allTags, onTagFilterChanged, tags = [] }) => {
    const _setSelectedTags = (t: Tag[]) => {
        onTagFilterChanged(t);
    };
    return (
        <FilterInputTemplate label={label}>
            <DeviceTagsBody
                allTags={allTags}
                tagAdded={(tag: string) => { _setSelectedTags([...tags, new Tag(tag)]) }}
                tagRemoved={(tag: string) => _setSelectedTags([...tags.filter(t => t.name != tag)])}
                tags={tags.map(t => t.name)} />
        </FilterInputTemplate>
    )
}