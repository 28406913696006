export class FirmwareView {
    name: string;
    constructor(name: string) {
        this.name = name;
    }
}

export class DeviceTypeView {
    name: string;
    constructor(name: string) {
        this.name = name;
    }
}

export class Device {
    id: number;
    name: string;
    device_type: string;
    fwVersion: string;
    fwVersionTime: string;
    targetVersion: string;
    lastComm: string;
    tags: string[];
    force_version: boolean;
    display_name: string;
    data: any
    constructor(id: number, name: string, deviceType: string, fwVersion: string, targetVersion: string, tags: string[], force_version: boolean, data: {}, fwVersionTime: string, lastComm: string, displayName: string) {
        this.id = id;
        this.name = name;
        this.display_name = displayName;
        this.device_type = deviceType;
        this.fwVersion = fwVersion;
        this.targetVersion = targetVersion;
        this.tags = tags;
        this.data = data;
        this.force_version = force_version;
        this.fwVersionTime = fwVersionTime;
        this.lastComm = lastComm;
    }
}

interface IDevicesAPI {
    getDevices(pageNum: number, countPerPage: number): Promise<Device[]>;
    getDeviceCount(queryParams: string): Promise<number>;
    getDevicesFiltered(queryParams: string, pageNum: number, countPerPage: number): Promise<Device[]>;
    getAllDevicesFiltered(queryParams: string): Promise<Device[]>;
    addDevice(user: Device): Promise<void>;
    deleteDevice(id: string, deviceType: string): Promise<void>;
    editDevice(user: Device, oldName: string): Promise<void>;
    getFirmwares(): Promise<FirmwareView[]>;
    getDeviceTypes(): Promise<DeviceTypeView[]>;
    removeTagFromDevice(deviceId: string, deviceType: string, tag: string): Promise<void>;
    addTagToDevice(deviceId: string, deviceType: string, tag: string): Promise<void>;
    updateDevices(devices:Device[]): Promise<void>;
    deleteDevices(deviceIds:string[]): Promise<void>;
    uploadDevicesFile(file: File): Promise<void>;
    requestFirmware(devices: Device[]): Promise<void>;
}

export default IDevicesAPI;