import React from 'react';

const LoginSplash : React.FunctionComponent = ({}) => {
    return (
        <div style={{display:'flex', flexDirection: 'column', marginLeft: 10, height: '100%', backgroundColor: 'rgb(30, 144, 255)', color: 'white'}}>
            <div style={{marginTop: '30%', marginLeft: 70}}>
                <span style={{fontSize: 40}}>Firmware Management System</span>
                <br/>
                <span>This system permits the management of firmware versions and OTA firmware updates</span>
            </div>
        </div>
    )
}

export default LoginSplash;