import { DeviceType, DownloadMethod, NotificationMechanism } from '@api/devicetypes/DeviceTypeAPI';
import TableRow from '@components/ui/layout/TableLayout/Row/TableRow';
import TextEntry from '@components/ui/layout/TableLayout/Text/TextEntry';
import React, { useState, useEffect } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import ToggleButton from '@components/ui/inputs/buttons/ToggleButton/ToggleButton';

interface IDeviceTypeRow {
    deviceType: DeviceType;
    editingRow: boolean;
    notificationMechanisms: NotificationMechanism[];
    downloadMethods: DownloadMethod[];
    editToggled: (newValue: boolean) => void;
}

const DeviceTypeRow: React.FunctionComponent<IDeviceTypeRow> = ({ deviceType, editingRow, editToggled, notificationMechanisms, downloadMethods }) => {
    const [editing, setEditing] = useState<boolean>(editingRow);
    useEffect(() => { setEditing(editingRow) }, [editingRow]);
    return (<TableRow>
        <div>
            <TextEntry label={deviceType.name}></TextEntry></div>
        <div>
            <TextEntry label={notificationMechanisms.find(nm => nm.id == deviceType.notification_method)?.name || ''}></TextEntry></div>
        <div>
            <TextEntry label={downloadMethods.find(nm => nm.id == deviceType.download_method)?.name || ''}></TextEntry></div>
        <div>
            <ToggleButton size={48} backgroundColor='#FFFFFF' active={editing} icon={FaPencilAlt} onChange={(newValue: boolean) => { editToggled(newValue) }} />
        </div>
    </TableRow>);
};

export default DeviceTypeRow;