import React, {useContext, useEffect, useRef, useState} from 'react';
import { useToasts } from 'react-toast-notifications';
import TextInput from '../../inputs/text/TextInput/TextInput';
import SubmitButton from '../../inputs/buttons/SubmitButton/SubmitButton';
import AuthFactory from '../../../../api/auth/AuthFactory';
import Checkbox from '../../inputs/checkboxes/Checkbox';
import AuthContext from '@context/AuthContext';

const LoginInput: React.FunctionComponent = () => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [username, _setUsername] = useState<string>('');
    const [password, _setPassword] = useState<string>('');
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [showRecoverForm, setShowRecoverForm] = useState<boolean>(false);
    const [sendingRecoverRequest, setSendingRecoverRequest] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const { addToast } = useToasts();
    const ctx = useContext(AuthContext);
    const usernameRef = useRef(username);
    const passwordRef = useRef(password);
    const setUsername = (newUsername: string) => {
        usernameRef.current = newUsername;
        _setUsername(newUsername);
    }
    const setPassword = (newPassword: string) => {
        passwordRef.current = newPassword;
        _setPassword(newPassword);
    }
    const _handleKeyDown = (e: any) => {
        if (e.keyCode == 13) {
            attemptLogin(usernameRef.current, passwordRef.current);
        }
    }
    useEffect(() => {
        document.addEventListener("keydown", _handleKeyDown);
        return () => {
            document.removeEventListener("keydown", _handleKeyDown);
        }
    }, []);
    //let history = useHistory();
    const attemptLogin = (username: string, password: string): void => {
        setLoading(true);
        AuthFactory.getAuthAPI().login(username, password, rememberMe).then((token) => {
            ctx.setToken(token);
            setLoading(false);
            //history.push('/dashboard');
        }).catch(error => {
            addToast('Login operation failed with error: ' + error, {appearance: 'error', autoDismiss: true})
            setLoading(false);
        });
    }
    return (
    <div style={{display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <span style={{color: '#707070', marginTop: 'auto'}}>Insert your credentials</span>
        <TextInput placeholder="Username" updateValue={(newValue: string) => {setUsername(newValue)}}></TextInput>
        <TextInput placeholder="Password" isPassword={true} updateValue={(newValue) => {setPassword(newValue)}}></TextInput>
        <SubmitButton label="Login" onClick={() => {attemptLogin(username, password)}} isLoading={isLoading} disabled={username == '' || password == ''}></SubmitButton>
        <span style={{color: '#707070', fontSize: 12, marginTop: 20, cursor: 'pointer'}} onClick={()=>{setShowRecoverForm(!showRecoverForm)}}><b>Forgot password?</b></span>
        {
            showRecoverForm &&
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <TextInput updateValue={(newVal: string) => {setEmail(newVal)}} placeholder="Email"/>
                <SubmitButton label="Send request" disabled={sendingRecoverRequest || email == ''} isLoading={sendingRecoverRequest} onClick={() => {
                    setSendingRecoverRequest(true);
                    AuthFactory.getAuthAPI().requestPasswordReset(email).then(() => {
                        addToast("Password reset request sent", {appearance: 'success', autoDismiss: true});
                        setSendingRecoverRequest(false);
                        setShowRecoverForm(false);
                    }).catch(error => {
                        addToast(error, {appearance: 'error', autoDismiss: true});
                        setSendingRecoverRequest(false);
                    });
                }}></SubmitButton>
            </div>
        }
        <div style={{marginTop: 'auto', marginBottom: 40}}>
            <Checkbox label="Remember me" checked={rememberMe} onUpdate={(newValue) => {setRememberMe(newValue)}}></Checkbox>
        </div>
    </div>)
}

export default LoginInput;