export const enTranslations = {
    "Cancel": "Cancel",
    "Add new": "Add new",
    "Dropdown filter expects options": "Dropdown filter expects options",
    "Select an entity in the left panel": "Select an entity in the left panel",
    "Firmware Management System": "Firmware Management System",
    "Name": "Name",
    "Address": "Address",
    "Target firmware version": "Target firmware version",
    "Device type": "Device type",
    "Device types": "Device types",
    "Device Type": "Device Type",
    "Device edited": "Changed device", 
    "Failed to edit device": "Failed to change device",
    "Device deleted": "Device deleted", 
    "Failed to delete device": "Failed to delete device",
    "Device created": "Device created",
    "Failed to create device":"Failed to create device. Check if a device with the same Mac and Device Type exists.",
    "Save": "Save",
    "Submit": "Submit",
    "Tags": "Tags",
    "Mac":"Mac Address",
    "Firmware Version": "Firmware Version",
    "Target Version": "Target Version",
    "No Target Version": "No Target Version",
    "Devices": "Devices",
    "Upload csv file": "Upload csv file",
    "Update command sent": "Update command sent",
    "Update command failed": "Update command failed",
    "Update firmware to target versions": "Update firmware to target versions",
    "Available tags": "Available tags",
    "Notification Method": "Notification Method",
    "Download Method": "Download Method",
    "Notification method": "Notification method",
    "Download method": "Download method",
    "Page load operation failed with error:":"Page load operation failed with error:",
    "Type to filter by name": "Type to filter by name",
    "Type to filter by address": "Type to filter by address",
    "File uploaded":"File uploaded",
    "File upload failed:": "File upload failed:",
    "Priority": "Priority",
    "Version": "Version",
    "Is default": "Is default",
    "Firmware name": "Firmware name",
    "Update period": "Update period",
    "Upload firmware bin file": "Upload firmware bin file",
    "Yes": "Yes",
    "No": "No",
    "Insert tag name": "Insert tag name",
    "Tag deleted": "Tag deleted", 
    "Tags deleted": "Tags deleted", 
    "Failed to delete tag": "Failed to delete tag",
    "Failed to delete tags": "Failed to delete tags",
    "Tag created": "Tag created",
    "Failed to create tag":"Failed to create tag",
    "Delete selected tags": "Delete selected tags",
    "Insert username": "Insert username",
    "Insert user email": "Insert user email",
    "Insert password": "Insert password",
    "Select permissions": "Select permissions",
    "Users": "Users",
    "Username": "Username",
    "Email": "Email",
    "Permission": "Permission",
    "Firmware Versions": "Firmware Versions",
    "New password": "New password",
    "Confirm password": "Confirm password",
    "Confirm new password": "Confirm new password",
    "Change password": "Change password",
    "Logout": "Logout",
    "Device management": "Device management",
    "CsvToolTip":`CSV must have an header line using the following names (case insensitive):
    - name              Type: string.                                       Required: yes.  Default: none
    - type              Type: string.                                       Required: yes.  Default: none
    - address           Type: string.                                       Required: no.   Default: none
    - target_version    Type: string.                                       Required: no.   Default: none
    - tags              Type: string, semicolons separated list of tags.    Required: no.   Default: none
    - force_version     Type: boolean (false|true|1|0).                     Required: no.   Default: false
    - trigger_update    Type: boolean (false|true|1|0).                     Required: no.   Default: false`,
    "Updates": "Updates",
    "Update Status": "Update Status",
    "Version Notified": "Version Notified"
};
