import ToggleButton from '@components/ui/inputs/buttons/ToggleButton/ToggleButton';
import TableRow from '@components/ui/layout/TableLayout/Row/TableRow';
import TextEntry from '@components/ui/layout/TableLayout/Text/TextEntry';
import React, { useEffect, useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { User } from 'src/api/user/UserAPI';
interface IUserRow {
    user: User;
    editingRow: boolean;
    editToggled: (newValue: boolean) => void;
}

const UserRow: React.FunctionComponent<IUserRow> = ({ user, editingRow, editToggled }) => {
    const [editing, setEditing] = useState<boolean>(editingRow);
    useEffect(() => { setEditing(editingRow) }, [editingRow]);
    return (
        <TableRow>
            <div>
                <TextEntry label={user.username} />
            </div>
            <div>
                <TextEntry label={user.email} />
            </div>
            <div>
                <TextEntry label={user.permission.label} />
            </div>
            <div>
                <ToggleButton size={48} backgroundColor='#FFFFFF' active={editing} icon={FaPencilAlt} onChange={(newValue: boolean) => { editToggled(newValue) }} />
            </div>
        </TableRow>
    )
};

export default UserRow;