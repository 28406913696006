import React from 'react';

import './TableRow.css';

const TableRow: React.FunctionComponent = ({children}) => {
    return (
        <div className="TableRow">
            {children}
        </div>
    )
}

export default TableRow;