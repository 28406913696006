import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './TextInput.css';

export interface TextInputProps {
    isPassword?: boolean;
    placeholder?: string;
    presetValue?: any;
    numeric?: boolean;
    updateValue: (newValue: string) => void;
}

const TextInput : React.FunctionComponent<TextInputProps> = (({isPassword=false, placeholder='', updateValue, presetValue = '', numeric = false}) => {
    const [val, setValue] = useState<string>(presetValue);
    const { t } = useTranslation();
    useEffect(() => {
        setValue(presetValue);
    }, [presetValue]);
    return (
        <input className="TextInput" type={isPassword?'password':numeric?'number':'text'} value={val} placeholder={t(placeholder)} onChange={(event) => {
            if(numeric && isNaN(+event.target.value)) return;
            setValue(event.target.value); updateValue(event.target.value)
        }}></input>
    );
});

export default TextInput;