import { isDev } from "@api/config";
import IDevicesAPI from "./DevicesAPI";
import LiveDevicesAPI from "./LiveDevicesAPI";
import MockDevicesAPI from "./MockDevicesAPI";
class DevicesAPIFactory {
    static mockAPI = new MockDevicesAPI();
    static liveAPI = new LiveDevicesAPI();
    static getDevicesAPI(): IDevicesAPI {
        if (isDev()) {
            return this.mockAPI;
        }
        return this.liveAPI;
    }
}

export default DevicesAPIFactory;