import FirmwareAPI, { Firmware, Priority } from "./FirmwareAPI";

class MockFirmwareAPI implements FirmwareAPI {
    downloadFirmware(filename: string): Promise<void> {
        return new Promise<void>(resolve => {
            setTimeout(() => {resolve()}, 500);
        });
    }
    uploadFirmware(filename: string, file: File): Promise<void> {
        return new Promise<void>(resolve => {
            console.log(filename);
            setTimeout(() => {resolve()}, 500);
        });
    }
    getFirmwarePriorities(): Promise<Priority[]> {
        return new Promise<Priority[]>((resolve) => {
            resolve([
                new Priority('None'),
                new Priority('Low'),
                new Priority('Medium'),
                new Priority('High')
            ]);
        });
    }
    getFirmwares(): Promise<Firmware[]> {
        return new Promise<Firmware[]>((resolve) => {
            let fws = [];
            for (let i = 0; i < 100; i++) {
                fws.push(new Firmware('Monometer Wifi', `1.0.${i}`, false, 'Low', `fwasdfsdafjihgbasdjkfasdfsdfaafsasdfsafdfsadasdfafsdfasdfsdaafsdfsdaafdsfasddfsadsadffasdfasdfadsçbnasfjkçbsadkjçfhbsakjçhfsdabyuobasljsfad_1.0.${i}.bin`, 120))
            }
            resolve(fws);
        });
    }
    addFirmware(f: Firmware): Promise<void> {
        return new Promise<void>(resolve => {
            console.log(JSON.stringify(f));
            setTimeout(() => {resolve()}, 500);
        });
    }
    deleteFirmware(f: Firmware): Promise<void> {
        return new Promise<void>(resolve => {
            setTimeout(() => {resolve()}, 500);
        });
    }
    editFirmware(f: Firmware): Promise<void> {
        return new Promise<void>(resolve => {
            setTimeout(() => {resolve()}, 500);
        });
    }
}

export default MockFirmwareAPI;