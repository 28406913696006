import { _axios } from "@api/axiosinstance";
import DeviceTypeAPIFactory from "@api/devicetypes/DeviceTypeAPIFactory";
import FirmwareAPIFactory from "@api/firmwares/FirmwareAPIFactory";
import DevicesAPI, { Device, DeviceTypeView, FirmwareView } from "./DevicesAPI";

class LiveDevicesAPI implements DevicesAPI {
    getAllDevicesFiltered(queryParams: string): Promise<Device[]> {
        return new Promise<Device[]>((resolve, reject) => {
            _axios.get('devices?'+queryParams).then(response => {
                if (response.status != 200) reject(response.statusText);
                resolve(response.data.map((d: any) => new Device(d.id, d.name, d.device_type, d.fwVersion, d.targetVersion, d.tags, d.force_version, d.data, d.fwVersionTime, d.lastComm, d.display_name)));
            }).catch(error => {
                reject(error);
            });
        });
    }
    requestFirmware(deviceIds: Device[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.put('requestfwversion', deviceIds).then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve();
            }).catch(error => reject(error));
        });
    }
    uploadDevicesFile(file: File): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            file.text().then(text => {
                _axios.post(`devices/csv_bulk`, text).then(response => {
                    if (response.status != 200) return reject(response.statusText);
                    resolve();
                }).catch(error => reject(error));
            }).catch(error => reject(error));
        })

    }
    getDeviceCount(queryParams: string): Promise<number> {
        return new Promise<number>((resolve, reject) => {
            _axios.get('devices/count?'+queryParams).then(response => {
                if (response.status != 200) reject(response.statusText);
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        });
    }
    getDevicesFiltered(queryParams: string, pageNum: number, countPerPage: number): Promise<Device[]> {
        return new Promise<Device[]>((resolve, reject) => {
            _axios.get('devices?'+queryParams+`&pageNum=${pageNum}&countPerPage=${countPerPage}`).then(response => {
                if (response.status != 200) reject(response.statusText);
                resolve(response.data.map((d: any) => new Device(d.id, d.name, d.device_type, d.fwVersion, d.targetVersion, d.tags, d.force_version, d.data, d.fwVersionTime, d.lastComm, d.display_name)));
            }).catch(error => {
                reject(error);
            });
        });
    }
    getDevices(pageNum: number, countPerPage: number): Promise<Device[]> {
        return new Promise<Device[]>((resolve, reject) => {
            _axios.get(`devices?pageNum=${pageNum}&countPerPage=${countPerPage}`).then(response => {
                if (response.status != 200) reject(response.statusText);
                resolve(response.data.map((d: any) => new Device(d.id, d.name, d.device_type, d.fwVersion, d.targetVersion, d.tags, d.force_version, d.data, d.fwVersionTime, d.lastComm, d.display_name)));
            }).catch(error => {
                reject(error);
            });
        });
    }
    addDevice(dev: Device): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.post('devices', {...dev}).then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }
    deleteDevice(id: string, deviceType: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.delete('devices/' + id+'/'+deviceType).then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve();
            }).catch(error => reject(error));
        })
    }
    editDevice(dev: Device, oldName: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.put('devices/' + oldName, {...dev}).then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve();
            }).catch(error => reject(error));
        });
    }
    getFirmwares(): Promise<FirmwareView[]> {
        return new Promise<FirmwareView[]>((resolve, reject) => {
            FirmwareAPIFactory.getFirmwareAPI().getFirmwares().then(result => {
                resolve(result.map(r => new FirmwareView(r.version)));
            }).catch(error => reject(error));
        });
    }
    getDeviceTypes(): Promise<DeviceTypeView[]> {
        return new Promise<DeviceTypeView[]>((resolve, reject) => {
            DeviceTypeAPIFactory.getDeviceTypeAPI().getDeviceTypes().then(result => {
                resolve(result.map(r => new DeviceTypeView(r.name)));
            }).catch(error => reject(error));
        });
    }
    removeTagFromDevice(deviceId: string, deviceType:string, tag: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.delete('device/' + deviceId +'/'+deviceType +'/tag/' + tag).then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve();
            }).catch(error => reject(error));
        });
    }
    addTagToDevice(deviceId: string, deviceType:string, tag: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.post('device/' + deviceId +'/'+deviceType + '/tag/' + tag).then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve();
            }).catch(error => reject(error));
        });
    }
    updateDevices(devices: Device[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.put('device_update', devices.map(dev => {return {...dev}})).then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve();
            }).catch(error => reject(error));
        });
    }
    deleteDevices(deviceIds: string[]): Promise<void> {
        throw new Error("Method not implemented.");
    }

}

export default LiveDevicesAPI;