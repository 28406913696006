import { NotificationMechanism } from '@api/devicetypes/DeviceTypeAPI';
import { DeviceUpdateStatus, UpdateStatus } from '@api/updates/UpdatesAPI';
import UpdatesAPIFactory from '@api/updates/UpdatesAPIFactory';
import ToggleButton from '@components/ui/inputs/buttons/ToggleButton/ToggleButton';
import TableRow from '@components/ui/layout/TableLayout/Row/TableRow';
import TextEntry from '@components/ui/layout/TableLayout/Text/TextEntry';
import withData from '@components/wrappers/DataLoader/DataLoader';
import withModal from '@components/wrappers/Modal/ModalContainer';
import React from 'react';
import { FaList } from 'react-icons/fa';
import DetailedUpdates, { IDetailedUpdates } from './DetailedUpdates';

interface IUpdateStatusRow {
    status: DeviceUpdateStatus;
    possibleStatuses: UpdateStatus[];
    notificationMechanisms: NotificationMechanism[];
    showHistory: boolean;
    showHistoryToggled: (newValue: boolean) => void;
}

const UpdateStatusRow: React.FunctionComponent<IUpdateStatusRow> = ({ status, possibleStatuses, notificationMechanisms, showHistory, showHistoryToggled }) => {
    const WrappedComponent = withModal(() => {showHistoryToggled(false)})(withData(() => UpdatesAPIFactory.getUpdatesAPI().getDetailedUpdateList(status.id))(DetailedUpdates));
    return (
        <TableRow>
                <div><TextEntry label={status.id}></TextEntry></div>
                <div><TextEntry label={possibleStatuses.find(n => n.id == status.state)?.name || ''}></TextEntry></div>
                <div><TextEntry label={notificationMechanisms.find(n => n.id == status.notification_method)?.name || ''}></TextEntry></div>
                <div><TextEntry label={status.utc_timestamp}></TextEntry></div>
                <div><ToggleButton size={48} backgroundColor='#FFFFFF' active={showHistory} icon={FaList} onChange={(newValue: boolean) => { showHistoryToggled(newValue) }} /></div>
                {
                    showHistory &&
                    <WrappedComponent notificationMechanisms={notificationMechanisms} possibleStatuses={possibleStatuses}/>
                }
        </TableRow>
    );
}

export default UpdateStatusRow;