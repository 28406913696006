import { isDev } from "@api/config";
import IDeviceTypeAPI from "./DeviceTypeAPI";
import LiveDeviceTypeAPI from "./LiveDeviceTypeAPI";
import MockDeviceTypeAPI from "./MockDeviceTypeAPI";
class DeviceTypeAPIFactory {
    static mockAPI = new MockDeviceTypeAPI();
    static liveAPI = new LiveDeviceTypeAPI();
    static getDeviceTypeAPI(): IDeviceTypeAPI {
        if(isDev())  {
            return this.mockAPI;
        }
        return this.liveAPI;
    }
}

export default DeviceTypeAPIFactory;