import { createContext } from "react";
import { Firmware } from '@api/firmwares/FirmwareAPI';
import { Tag } from "@api/tags_api/TagsAPI";

interface IUpdateFilterContext {
  filters: any;
  setUpdateFilters: (filters:any) => void;
  getUpdateFilters: () => any;
}

const UpdateFilterContext = createContext<IUpdateFilterContext>({filters: {}, setUpdateFilters: (filters:any) => {}, getUpdateFilters: () => {return {}}});

export default UpdateFilterContext;