import ToggleButton from '@components/ui/inputs/buttons/ToggleButton/ToggleButton';
import React, { ComponentType } from 'react';
import { FaTimes } from 'react-icons/fa';
import classes from './Modal.module.css';

function withModal(onClose: () => void): (Component: ComponentType<any>) => React.FunctionComponent<any> {
    return (Component: ComponentType<any>) => (props: any) => {
        return (
            <>
                <div className={classes.Modal}>
                    <ToggleButton active={false} backgroundColor="white" icon={FaTimes} onChange={onClose} size={48} />
                    <div style={{height: '90%'}}>
                        <Component {...props}/>
                    </div>
                </div>
                <div className={classes.Backdrop}></div>
            </>
        );
    };
}

export default withModal;


