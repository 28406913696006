import { _axios } from "@api/axiosinstance";
import FirmwareAPI, { Firmware, Priority } from "./FirmwareAPI";
const FileDownload = require('js-file-download');

class LiveFirmwareAPI implements FirmwareAPI {
    downloadFirmware(filename: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.get(`/firmware/firmware/${filename}`, {responseType: 'blob'}).then(response => {
                if (response.status != 200) return reject(response.statusText);
                FileDownload(response.data, filename);
                resolve();
            }).catch(error => reject(error));
        });
    }
    uploadFirmware(filename: string, file: File): Promise<void> {
        let formData = new FormData();
        formData.append("file", file);
        return new Promise<void>((resolve, reject) => {
            _axios.post(`/firmware/firmware/${filename}`, formData, {headers: {'Content-Type':'multipart/form-data'}}).then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve();
            }).catch(error => reject(error));
        });
    }
    getFirmwares(): Promise<Firmware[]> { //firmware/version
        return new Promise<Firmware[]>((resolve, reject) => {
            _axios.get('/firmware/version').then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve(response.data.map((j: any) => new Firmware(j.device_type, j.version, j.is_default, j.priority, j.firmware_url, j.data?.update_period)));
            }).catch(error => reject(error));
        });
    }
    getFirmwarePriorities(): Promise<Priority[]> {
        return new Promise<Priority[]>((resolve) => {
            resolve([
                new Priority('None'),
                new Priority('Low'),
                new Priority('Medium'),
                new Priority('High')
            ]);
        });
    }
    addFirmware(f: Firmware): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.post('/firmware/version', {...f, data: {update_period: f.updatePeriod}, force_update: false}).then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve();
            }).catch(error => reject(error));
        });
    }
    deleteFirmware(f: Firmware): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.delete('/firmware/version/' + f.device_type + '/' + f.version).then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve();
            }).catch(error => reject(error));
        })
    }
    editFirmware(f: Firmware): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.put('/firmware/version', {...f, data: {update_period: f.updatePeriod}, force_update: false}).then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve();
            }).catch(error => reject(error));
        });
    }

}

export default LiveFirmwareAPI;