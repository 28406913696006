import IDeviceTypeAPI, { DeviceType, DownloadMethod, NotificationMechanism } from './DeviceTypeAPI';

class MockDeviceTypeAPI implements IDeviceTypeAPI {
    getDownloadMethods(): Promise<DownloadMethod[]> {
        return new Promise((resolve) => {
            resolve([new DownloadMethod(1, 'FTP'), new DownloadMethod(2, 'HTTP')]);
        });
    }
    getNotificationMechanisms(): Promise<NotificationMechanism[]> {
        return new Promise((resolve) => {
            resolve([new NotificationMechanism(1, 'TCP'), new NotificationMechanism(2, 'UDP')]);
        });
    }
    addDeviceType(user: DeviceType): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, 500);
        });
    }
    deleteDeviceType(id: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, 500);
        });
    }
    editDeviceType(user: DeviceType, oldName: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, 500);
        });
    }
    getDeviceTypes(): Promise<DeviceType[]> {
        return new Promise<DeviceType[]>((resolve, reject) => {
            setTimeout(() => {
                const users:DeviceType[] = [];
                for (let i = 0; i < 9; i++) {
                    users.push(new DeviceType('DeviceType_' + i, 1, 1, {}));
                };
                resolve(
                    users
                );
            }, 500);
        });
    }

}

export default MockDeviceTypeAPI;