import { DeviceType, DownloadMethod, NotificationMechanism } from '@api/devicetypes/DeviceTypeAPI';
import DeviceTypeAPIFactory from '@api/devicetypes/DeviceTypeAPIFactory';
import { DropdownFilterInput, TextFilterInput } from '@components/ui/inputs/filter/FilterInput';
import DashboardLayout from '@components/ui/layout/DashboardLayout/DashboardLayout';
import ExpandableFilterLayout from '@components/ui/layout/ExpandableFilterLayout/ExpandableFilterLayout';
import withData, { DataProp } from '@components/wrappers/DataLoader/DataLoader';
import DeviceTypeFilterContext from '@context/DeviceTypeFilterContext';
import React, { useContext, useEffect, useState } from 'react';
import AddEditPanel from './AddEditPanel';
import DeviceTypeRow from './DeviceTypeRow';

const DeviceTypesDashboard: React.FunctionComponent<DataProp<[DeviceType[], NotificationMechanism[], DownloadMethod[]]>> = ({data}) => {
    const filtersContext = useContext(DeviceTypeFilterContext);
    const [searchText, setSearchText] = useState<string>('');
    const [nameFilter, setNameFilter] = useState<string>((filtersContext.getDeviceTypeFilters().name)?filtersContext.getDeviceTypeFilters().name:'');
    const [downloadMethodFilter, setDownloadMethodFilter] = useState<number>((filtersContext.getDeviceTypeFilters().downloadMethod)?filtersContext.getDeviceTypeFilters().downloadMethod:-1);
    const [notificationMethodFilter, setNotificationMethodFilter] = useState<number>((filtersContext.getDeviceTypeFilters().notificationMethod)?filtersContext.getDeviceTypeFilters().notificationMethod:-1);
    const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>(data[0]);
    const [filteredDeviceTypes, setFilteredDeviceTypes] = useState<DeviceType[]>(data[0]);
    const [notificationMechanisms] = useState<NotificationMechanism[]>(data[1]);
    const [downloadMethods] = useState<DownloadMethod[]>(data[2]);
    const [editingDeviceType, setEditingDeviceType] = useState<DeviceType | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const maxEntries = 8;

    useEffect(() => {
        filter();
    }, [searchText, nameFilter, downloadMethodFilter, notificationMethodFilter, deviceTypes]);

    useEffect(() => {
        setCurrentPage(1);
    }, [filteredDeviceTypes]);

    const filter = () => {
        let baseDeviceTypes = [...deviceTypes];
        if (searchText != "") {
            baseDeviceTypes = baseDeviceTypes.filter(u => u.name.toLowerCase().includes(searchText.toLowerCase()));
        }
        if (nameFilter != "") {
            baseDeviceTypes = baseDeviceTypes.filter(u => u.name.toLowerCase().includes(nameFilter.toLowerCase()));
        }
        if (downloadMethodFilter != -1) {
            baseDeviceTypes = baseDeviceTypes.filter(u => u.download_method == downloadMethodFilter);
        }
        if (notificationMethodFilter != -1) {
            baseDeviceTypes = baseDeviceTypes.filter(u => u.notification_method == notificationMethodFilter);
        }
        filtersContext.setDeviceTypeFilters({name:nameFilter, notificationMethod:notificationMethodFilter, 
            downloadMethod: downloadMethodFilter});
        setFilteredDeviceTypes(baseDeviceTypes);
    }
    const resetFilters = () => {
        setNameFilter('');
        setDownloadMethodFilter(-1);
        setNotificationMethodFilter(-1);
    }
    const filters: React.ReactNode[] = [
        <TextFilterInput label="Name" placeholder="Type to filter by name" updateValue={(newValue: any) => {setNameFilter(newValue)}} presetValue={nameFilter}/>,
        <DropdownFilterInput label="Notification method" selectedOption={notificationMethodFilter+ ""} onSelect={(newValue: any) => { setNotificationMethodFilter(newValue); console.log(newValue); }} placeholder="Notification Method" dropdownOptions={notificationMechanisms.map(mech => { return { id: mech.id+"", label: mech.name } })}/>,
        <DropdownFilterInput label="Download method" selectedOption={downloadMethodFilter +""} onSelect={(newValue: any) => { setDownloadMethodFilter(newValue) }} placeholder="Download Method" dropdownOptions={downloadMethods.map(mech => { return { id: mech.id +"", label: mech.name } })}/>,
    ];
    const deleteDeviceType = (id: string) => {
        return new Promise<void>((resolve, reject) => {
            DeviceTypeAPIFactory.getDeviceTypeAPI().deleteDeviceType(id).then(()=>{
                setDeviceTypes(deviceTypes.filter(u => u.name != id));
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    const editDeviceType = (deviceType: DeviceType, oldName: string) => {
        return new Promise<void>((resolve, reject) => {
            deviceType.download_method = +deviceType.download_method;
            deviceType.notification_method = +deviceType.notification_method;
            DeviceTypeAPIFactory.getDeviceTypeAPI().editDeviceType(deviceType, oldName).then(()=>{
                let idx = deviceTypes.findIndex(u => u.name == oldName);
                let newUsers = [...deviceTypes];
                newUsers[idx] = deviceType;
                setDeviceTypes(newUsers);
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }
    
    const createDeviceType = (deviceType: DeviceType) => {
        return new Promise<void>((resolve, reject) => {
            deviceType.download_method = +deviceType.download_method;
            deviceType.notification_method = +deviceType.notification_method;
            DeviceTypeAPIFactory.getDeviceTypeAPI().addDeviceType(deviceType).then((id)=>{
                setDeviceTypes([...deviceTypes, deviceType]);
                setEditingDeviceType(null);
                resolve();
            }).catch(error => {
                setEditingDeviceType(null);
                reject(error);
            });
        });
    }

    return (
        <DashboardLayout
            tableProps={{
                tableHeaders:["Name", "Notification method", "Download method"],
                tableFooterInfo: {currentPage: currentPage,
                    numberOfEntries: filteredDeviceTypes.length,
                    entriesPerPage: maxEntries,
                    pageSelected: (newPage: number) => {setCurrentPage(newPage)}
                }
            }}
            title="Device types"
            filter={{filter: ExpandableFilterLayout, props:{
                placeholder: 'Search by name', onReset: resetFilters, filterItems: filters, onTextSearchChange: (newValue: string) => {setSearchText(newValue)}}}}
            tableFooter={<AddEditPanel onEdit={editDeviceType} onDelete={deleteDeviceType} onSubmit={createDeviceType} downloadMethods={downloadMethods} editMode={editingDeviceType ? true : false} deviceType={editingDeviceType? editingDeviceType : new DeviceType('', 
            (filtersContext.getDeviceTypeFilters().notificationMethod)?parseInt(filtersContext.getDeviceTypeFilters().notificationMethod):-1, 
            (filtersContext.getDeviceTypeFilters().downloadMethod)?parseInt(filtersContext.getDeviceTypeFilters().downloadMethod):-1, {})} notificationMechanisms={notificationMechanisms} onClose={() => {setEditingDeviceType(null);}}></AddEditPanel>}
        >
            {filteredDeviceTypes.slice((currentPage-1) * maxEntries, (currentPage-1) * maxEntries + maxEntries).map(devtype => <DeviceTypeRow key={devtype.name} downloadMethods={downloadMethods} notificationMechanisms={notificationMechanisms} deviceType={devtype} editingRow={(editingDeviceType ? editingDeviceType.name == devtype.name : false)} editToggled={(toggled: boolean)=>{ toggled ? setEditingDeviceType(devtype) : setEditingDeviceType(null);}}></DeviceTypeRow>)}
        </DashboardLayout>
    );
}

export default withData(() => Promise.all([DeviceTypeAPIFactory.getDeviceTypeAPI().getDeviceTypes(), DeviceTypeAPIFactory.getDeviceTypeAPI().getNotificationMechanisms(), DeviceTypeAPIFactory.getDeviceTypeAPI().getDownloadMethods()]))(DeviceTypesDashboard);