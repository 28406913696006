import { isDev } from "@api/config";
import IFirmwareAPI from "./FirmwareAPI";
import LiveFirmwareAPI from "./LiveFirmwareAPI";
import MockFirmwareAPI from "./MockFirmwareAPI";
class FirmwareAPIFactory {
    static mockAPI = new MockFirmwareAPI();
    static liveAPI = new LiveFirmwareAPI();
    static getFirmwareAPI(): IFirmwareAPI {
        if (isDev()) {
            return this.mockAPI;
        }
        return this.liveAPI;
    }
}

export default FirmwareAPIFactory;