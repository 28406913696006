import React, { useEffect, useState } from 'react';
import './App.css';
import LoginPage from '@pages/login/Login';
import PageNotFound from '@pages/error/PageNotFound';
import AuthContext from '@context/AuthContext';
import DeviceFilterContext from '@context/DeviceFilterContext';
import DeviceTypeFilterContext from '@context/DeviceTypeFilterContext';
import UsersFilterContext from '@context/UsersFilterContext';
import FirmwareFilterContext from '@context/FirmwareFilterContext';
import TagsFilterContext from '@context/TagsFilterContext';
import UpdateFilterContext from '@context/UpdateFilterContext';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Dashboard from '@components/ui/pages/dashboard/Dashboard';
import { _axios } from '@api/axiosinstance';
import ResetPassword from '@components/ui/pages/reset_password/ResetPassword';
import { useTranslation } from 'react-i18next';

function App() {
  console.log(navigator.language);
  const getToken = () => {
    let t = localStorage.getItem('token');
    if (t != null) {
      _axios.defaults.headers.common["SessionToken"] = t;
    }
    return t;
  }
  const [token, _setToken] = useState<string | null>(getToken());
  
  function setToken(token: string | null) {
    token == null ? localStorage.removeItem('token') : localStorage.setItem('token', token);
    _axios.defaults.headers.common["SessionToken"] = token;
    _setToken(token);
  }

  function getDeviceFilters() {
    let df = localStorage.getItem('deviceFilters');
    console.log(df);
    return (df)?JSON.parse(df):{};
  }
  function getDeviceTypeFilters() {
    let dtf = localStorage.getItem('deviceTypeFilters');
    console.log(dtf);
    return (dtf)?JSON.parse(dtf):{};
  }
  function getUsersFilters() {
    let usf = localStorage.getItem('usersFilters');
    console.log(usf);
    return (usf)?JSON.parse(usf):{};
  }
  function getUpdateFilters() {
    let uf = localStorage.getItem('updateFilters');
    console.log(uf);
    return (uf)?JSON.parse(uf):{};
  }
  function getTagsFilters() {
    let tf = localStorage.getItem('tagsFilters');
    console.log(tf);
    return (tf)?JSON.parse(tf):{};
  }
  function getFirmwareFilters() {
    let ff = localStorage.getItem('firmwareFilters');
    console.log(ff);
    return (ff)?JSON.parse(ff):{};
  }

  const [deviceFilters, _setDeviceFilters] = useState<any>(getDeviceFilters());
  const [deviceTypeFilters, _setDeviceTypeFilters] = useState<any>(getDeviceTypeFilters());
  const [firmwareFilters, _setFirmwareFilters] = useState<any>(getFirmwareFilters());
  const [updateFilters, _setUpdateFilters] = useState<any>(getUpdateFilters());
  const [usersFilters, _setUsersFilters] = useState<any>(getUsersFilters());
  const [tagsFilters, _setTagsFilters] = useState<any>(getTagsFilters());

  function setDeviceFilters(filters:any) {
    console.log('Setting device filters ', filters);
    localStorage.setItem('deviceFilters', JSON.stringify(filters));
    _setDeviceFilters(filters);
  }
  function setDeviceTypeFilters(filters:any) {
    console.log('Setting device type filters ', filters);
    localStorage.setItem('deviceTypeFilters', JSON.stringify(filters));
    _setDeviceTypeFilters(filters);
  }
  function setFirmwareFilters(filters:any) {
    console.log('Setting firmware filters ', filters);
    localStorage.setItem('firmwareFilters', JSON.stringify(filters));
    _setFirmwareFilters(filters);
  }
  function setTagsFilters(filters:any) {
    console.log('Setting tags filters ', filters);
    localStorage.setItem('tagsFilters', JSON.stringify(filters));
    _setTagsFilters(filters);
  }
  function setUpdateFilters(filters:any) {
    console.log('Setting update filters ', filters);
    localStorage.setItem('updateFilters', JSON.stringify(filters));
    _setUpdateFilters(filters);
  }
  function setUsersFilters(filters:any) {
    console.log('Setting users filters ', filters);
    localStorage.setItem('usersFilters', JSON.stringify(filters));
    _setUsersFilters(filters);
  }

  const {t} = useTranslation();
  useEffect(() => {
    document.title = t('Device management');
    let responseInterceptorId = _axios.interceptors.response.use(response => {
      return response
    }, error => {
      if (error.response) {
        if (error.response.status === 401) {
          setToken(null);
        }
      }
      return Promise.reject(error);
    });
    return () => {
      _axios.interceptors.response.eject(responseInterceptorId);
    }
  }, []);
  return (
    <div className="App">
      <AuthContext.Provider value={{ token, setToken }}>
       <DeviceFilterContext.Provider value={{filters: deviceFilters, setDeviceFilters, getDeviceFilters}}>
       <DeviceTypeFilterContext.Provider value={{filters: deviceTypeFilters, setDeviceTypeFilters, getDeviceTypeFilters}}>
       <FirmwareFilterContext.Provider value={{filters: firmwareFilters, setFirmwareFilters, getFirmwareFilters}}>
       <UsersFilterContext.Provider value={{filters: usersFilters, setUsersFilters, getUsersFilters}}>
       <UpdateFilterContext.Provider value={{filters: updateFilters, setUpdateFilters, getUpdateFilters}}>
       <TagsFilterContext.Provider value={{filters: tagsFilters, setTagsFilters, getTagsFilters}}>
          {
            token != null ?
              <Router>
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/dashboard"></Redirect>
                  </Route>
                  <Route path="/dashboard">
                    <Dashboard/>
                  </Route>
                  <Route path="*">
                    <PageNotFound />
                  </Route>
                </Switch>
              </Router> :
              <Router>
                <Switch>
                  <Route exact path="/">
                    <LoginPage></LoginPage>
                  </Route>
                  <Route path="/reset/:resetToken">
                    <ResetPassword/>
                  </Route>
                  <Route path="*">
                    <Redirect to="/"/>
                  </Route>
                </Switch>
              </Router>
          }
        </TagsFilterContext.Provider>
        </UpdateFilterContext.Provider>
        </UsersFilterContext.Provider>
        </FirmwareFilterContext.Provider>
        </DeviceTypeFilterContext.Provider>
        </DeviceFilterContext.Provider>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
