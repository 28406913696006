import { createContext } from "react";
import { Firmware } from '@api/firmwares/FirmwareAPI';
import { Tag } from "@api/tags_api/TagsAPI";

interface ITagsFilterContext {
  filters: any;
  setTagsFilters: (filters:any) => void;
  getTagsFilters: () => any;
}

const TagsFilterContext = createContext<ITagsFilterContext>({filters: {}, setTagsFilters: (filters:any) => {}, getTagsFilters: () => {return {}}});

export default TagsFilterContext;