import React, { useState } from 'react';
import './ExpandableFilterLayout.css';
import ToggleButton from '@ui/inputs/buttons/ToggleButton/ToggleButton';
import TextSearch, { TextSearchProps } from '@components/ui/inputs/text/TextSearch/TextSearch';
import FilterLayout from '../FilterLayout/FilterLayout';
import { FaFilter } from 'react-icons/fa';
import SubmitButton from '@components/ui/inputs/buttons/SubmitButton/SubmitButton';
import { useDetectClickOutside } from 'react-detect-click-outside';

export interface FilterLayoutProps extends TextSearchProps {
    filterItems: React.ReactNode[];
    onReset: () => void;
    onSubmit?: () => void;
    onTextSearchSubmit?: () => void;
}

const ExpandableFilterLayout: React.FunctionComponent<FilterLayoutProps> = ({ onTextSearchChange, filterItems, onSubmit, onTextSearchSubmit, onReset }) => {
    const [filterTabActive, setFilterTabActive] = useState<boolean>(false);
    const ref = useDetectClickOutside({onTriggered: (event) => {
        setFilterTabActive(false);
    }});
    const searchPanelCSS = [
        "SearchBarPanel"
    ];
    if (filterTabActive) {
        searchPanelCSS.push('expanded');
    }
    return (
        <div ref={ref} style={{ overflow: 'hidden' }}>
            <div className={searchPanelCSS.join(' ')}>
                <div style={{ marginRight: '10px' }}>
                    <TextSearch onTextSearchChange={onTextSearchChange} onTextSearchSubmit={onTextSearchSubmit} placeholder='Search by name'/>
                </div>
                <ToggleButton active={filterTabActive} onChange={(toggled) => { setFilterTabActive(toggled); }} icon={FaFilter} size={56} backgroundColor='#EDF6FF' />
                {
                    onSubmit ?
                    <FilterLayout active={filterTabActive} onReset={onReset} filterItems={filterItems} onSubmit={() => { onSubmit(); setFilterTabActive(!filterTabActive); }} />
                        :
                        <FilterLayout active={filterTabActive} onReset={onReset} filterItems={filterItems} />
                }
            </div>

        </div>
    );
}

export default ExpandableFilterLayout;