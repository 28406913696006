import { createContext } from "react";
import { Firmware } from '@api/firmwares/FirmwareAPI';
import { Tag } from "@api/tags_api/TagsAPI";

interface IDeviceFilterContext {
  /*name: string;
  data: any;
  firmware: Firmware;
  target: Firmware;
  deviceType: string;
  tags: Tag[];*/
  filters: any;
  setDeviceFilters: (filters:any) => void;
  getDeviceFilters: () => any;
}

const DeviceFilterContext = createContext<IDeviceFilterContext>({filters: {}, setDeviceFilters: (filters:any) => {}, getDeviceFilters: () => {return {}}});

export default DeviceFilterContext;