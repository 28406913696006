import React from 'react';
import './SplitLayout.css';

interface SplitLayoutProps {
    leftPanel: React.FunctionComponent;
    rightPanel: React.FunctionComponent;
}

const SplitLayout: React.FunctionComponent<SplitLayoutProps> = ({leftPanel, rightPanel}) => {
    return (
        <div className="PanelContainer">
            <div className="LeftPanel">
                {leftPanel({})}
            </div>
            <div className="RightPanel">
                {rightPanel({})}
            </div>
        </div>
    )
}

export default SplitLayout;