import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaBackward, FaChevronLeft, FaChevronRight, FaForward } from 'react-icons/fa';
import Paginator from './Paginator/Paginator';
import './TableLayout.css';

interface ITableHeader {
    tableHeaders: string[];
    selectAll?: (toggled: boolean) => void;
};

interface ITableFooter {
    numberOfEntries: number;
    entriesPerPage: number;
    currentPage: number;
    pageSelected: (newPageNumber: number) => void;
}

export interface ITable extends ITableHeader {
    tableFooterInfo?: ITableFooter
}

const TableLayout: React.FunctionComponent<ITable> = ({ tableHeaders, children, selectAll, tableFooterInfo }) => {
    const { t } = useTranslation();
    const [selectedAll, setSelectedAll] = useState<boolean>(false);
    const headers = tableHeaders.map((h, idx) => {
        return (
            <div className="TableHeader" key={idx}>
                {t(h)}
            </div>
        )
    });

    return (
        <div style={{width: '100%', height: '100%'}}>
            <div style={{height: '90%', overflowY: 'auto'}}>
                <div className="TableLayout">
                    <div className="TableHeaderContainer">
                        {headers}
                        {selectAll &&
                            (<div className="TableHeader" style={{ color: '#1E90FF', cursor: 'pointer' }} onClick={() => { setSelectedAll(!selectedAll); selectAll(!selectedAll) }}>
                                <span>{selectedAll ? 'Unselect all' : 'Select all'}</span>
                            </div>)
                        }
                    </div>
                    <div className="TableBodyContainer">
                        {children}
                    </div>
                </div>
            </div>
            <div className="TableFooterContainer">
                {
                    tableFooterInfo &&
                    <Paginator numberOfEntries={tableFooterInfo.numberOfEntries} entriesPerPage={tableFooterInfo.entriesPerPage} currentPage={tableFooterInfo.currentPage} pageSelected={tableFooterInfo.pageSelected}/>
                }
            </div>
        </div>
    );
};

export default TableLayout;