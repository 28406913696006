import { Tag } from '@api/tags_api/TagsAPI';
import TagsAPIFactory from '@api/tags_api/TagsAPIFactory';
import SubmitButton from '@components/ui/inputs/buttons/SubmitButton/SubmitButton';
import Checkbox from '@components/ui/inputs/checkboxes/Checkbox';
import TextSearch from '@components/ui/inputs/text/TextSearch/TextSearch';
import DashboardLayout from '@components/ui/layout/DashboardLayout/DashboardLayout';
import Paginator from '@components/ui/layout/TableLayout/Paginator/Paginator';
import withData, { DataProp } from '@components/wrappers/DataLoader/DataLoader';
import TagsFilterContext from '@context/TagsFilterContext';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import AddEditPanel from './AddEditPanel';
import TagView from './Tag';
import './Tags.css';
const TagsDashboard: React.FunctionComponent<DataProp<Tag[]>> = ({ data }) => {
    const filtersContext = useContext(TagsFilterContext);
    const [tags, setTags] = useState<Tag[]>(data);
    const [currentPage, setPage] = useState(1);
    const [filteredTags, setFilteredTags] = useState<Tag[]>(data);
    const [editingTag, setTag] = useState<Tag | undefined>(undefined);
    const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
    const [selectedAll, setSelectedAll] = useState<boolean>(false);
    const [deletingTags, setDeletingTags] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>((filtersContext.getTagsFilters().name)?filtersContext.getTagsFilters().name:'');
    const { addToast } = useToasts();
    const { t: tr } = useTranslation();
    const maxTagsPerPage = 32;
    useEffect(() => {
        filter();
    }, [searchText, tags]);
    
    const createTag = (t: Tag) => {
        return new Promise<void>((resolve, reject) => {
            TagsAPIFactory.getTagsAPI().addTag(t).then(res => {
                setTags([...tags, t]);
                addToast(tr('Tag created'), { appearance: 'success', autoDismiss: true });
                resolve();
            }).catch(err => {
                reject(err);
                addToast(tr('Failed to create tag'), { appearance: 'error', autoDismiss: true });
            });
        });
    }
    const onTextSearchChange = (newVal: string) => {
        setSearchText(newVal);
    }
    const filter = () => {
        let baseTags = [...tags];
        if (searchText != '') {
            baseTags = baseTags.filter(f => f.name.includes(searchText));
        }
        filtersContext.setTagsFilters({name:searchText});
        setFilteredTags(baseTags);
    }
    return (
        <DashboardLayout tableProps={{ tableHeaders: [] }}
            tableFooter={<AddEditPanel onSubmit={createTag} editMode={editingTag ? true : false} tag={editingTag} onClose={() => { setTag(undefined) }}></AddEditPanel>} title="Tags" empty={true}>
            <div style={{ width: '100%', height: '100%', display:'flex', flexDirection:'column' }}>
                <div style={{height: '90%'}}>
                <div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginLeft: 'auto' }}>
                            <TextSearch defaultValue={searchText} onTextSearchChange={onTextSearchChange} placeholder='Search by name'/>
                        </div>
                    </div>
                    <span style={{ marginLeft: 'auto', marginRight: 25, color: '#1E90FF', cursor: 'pointer' }} onClick={() => {
                        if (selectedAll) {
                            setSelectedTags([]);
                        } else {
                            setSelectedTags([...filteredTags]);
                        }
                        setSelectedAll(!selectedAll);
                    }}>{selectedAll ? "Unselect all" : "Select all"}</span>
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}>
                    {filteredTags.slice((currentPage-1) * maxTagsPerPage, (currentPage-1) * maxTagsPerPage + maxTagsPerPage).map(tag =>
                    (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <div style={{ margin: 20 }}>
                                <Checkbox label="" onUpdate={(checked) => { checked ? setSelectedTags([...selectedTags, tag]) : setSelectedTags([...selectedTags.filter(t => t.name != tag.name)]) }} checked={selectedTags.findIndex(t => t.name == tag.name) >= 0}></Checkbox>
                            </div>
                            <TagView tag={tag} tagRemoved={() => { setTags([...tags.filter(t => t.name != tag.name)]) }}></TagView>
                        </div>
                    )
                    )}
                    </div>
                    </div>
                <div style={{marginLeft: 'auto'}}>
                    <Paginator currentPage={currentPage} entriesPerPage={maxTagsPerPage} numberOfEntries={filteredTags.length} pageSelected={(newPage) => setPage(newPage)}/>
                </div>
                
                <div className={selectedTags.length > 0 ? "BottomDrawer expanded" : "BottomDrawer"}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <span className="BottomDrawerTitle">{selectedTags.length} tags selected</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <SubmitButton isDelete={true} disabled={deletingTags} isLoading={deletingTags} onClick={() => {
                            setDeletingTags(true);
                            TagsAPIFactory.getTagsAPI().deleteTags(selectedTags).then(() => {
                                setDeletingTags(false);
                                setTags(tags.filter(t => !selectedTags.includes(t)));
                                setSelectedTags([]);
                                addToast(tr('Tags deleted'), { appearance: 'success', autoDismiss: true });
                            }).catch((error) => {
                                setDeletingTags(false);
                                addToast(tr('Failed to delete tags'), { appearance: 'error', autoDismiss: true });
                            });
                        }} label="Delete selected tags"></SubmitButton>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}

export default withData(TagsAPIFactory.getTagsAPI().getTags)(TagsDashboard);