import React from 'react';
import './SubmitButton.css';
import PulseLoader from 'react-spinners/PulseLoader';
import { useTranslation } from 'react-i18next';

interface ButtonProps {
    label: string;
    onClick: () => void;
    isLoading?: boolean;
    disabled?: boolean;
    isDelete?:  boolean;
    isSubmit?: boolean
}

const SubmitButton: React.FunctionComponent<ButtonProps> = ({label, onClick, isLoading=false, disabled=false, isDelete=false, isSubmit=true}) => {
    const { t } = useTranslation();
    return (
        <button type={isSubmit ? "submit" : "button"} className={isDelete ? "SubmitButton delete" : "SubmitButton"} disabled={disabled} onClick={onClick}>{isLoading ? <PulseLoader color="white" size={8}/> : t(label)}</button>
    );
};

export default SubmitButton;