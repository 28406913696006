import { NotificationMechanism } from '@api/devicetypes/DeviceTypeAPI';
import { DeviceUpdateStatus, UpdateStatus } from '@api/updates/UpdatesAPI';
import DashboardLayout from '@components/ui/layout/DashboardLayout/DashboardLayout';
import { DataProp } from '@components/wrappers/DataLoader/DataLoader';
import React from 'react';
import DetailedUpdateRow from './DetailedUpdateRow';

export interface IDetailedUpdates extends DataProp<DeviceUpdateStatus[]> {
    notificationMechanisms: NotificationMechanism[];
    possibleStatuses: UpdateStatus[];
}

const DetailedUpdates: React.FunctionComponent<IDetailedUpdates> = ({data, notificationMechanisms, possibleStatuses}) => {
    const tableHeaders = ['Device', 'State', 'Notification method', 'Timestamp'];
    return (
        <DashboardLayout 
            title={`Detailed Update Status`}
            tableFooter={<></>}
            tableProps={{
                tableHeaders: tableHeaders
            }}
        >
            {data.map(status => <DetailedUpdateRow status={status} notificationMechanisms={notificationMechanisms} possibleStatuses={possibleStatuses}/>)}
        </DashboardLayout>
    )
}

export default DetailedUpdates;