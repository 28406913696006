import { isDev } from "@api/config";
import LiveUpdatesAPI from "./LiveUpdatesAPI";
import MockUpdatesAPI from "./MockUpdatesAPI";
import IUpdatesAPI from "./UpdatesAPI";
class UpdatesAPIFactory {
    static mockAPI = new MockUpdatesAPI();
    static liveAPI = new LiveUpdatesAPI();
    static getUpdatesAPI(): IUpdatesAPI {
        if (isDev()) {
            return this.mockAPI;
        }
        return this.liveAPI;
    }
}

export default UpdatesAPIFactory;