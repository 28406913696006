import { _axios } from '@api/axiosinstance';
import apiPath from '@api/config';
import UserAPI, { Permission, User } from './UserAPI'

class LiveUserAPI implements UserAPI {
    getUsers(): Promise<User[]> {
        return new Promise<User[]>((resolve, reject) => {
            _axios.get('users').then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve(response.data.map((content: any) => new User(content.id, content.username, content.email, content.password, new Permission(content.permission.id, content.permission.label))))
            }).catch(error => reject(error));
        })
    }
    getPossiblePermissions(): Promise<Permission[]> {
        return new Promise<Permission[]>((resolve, reject) => {
            _axios.get('permissions/all').then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve(response.data.map((content: any) => new Permission(content.id, content.label)));
            }).catch(error => reject(error));
        });
    }
    addUser(user: User): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            _axios.post('user', user).then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve(response.data);
            }).catch(error => reject(error));
        });
    }
    deleteUser(id: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.delete('user/' + id).then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve();
            }).catch(error => reject(error));
        });
    }
    editUser(user: User): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            _axios.put('user', user).then(response => {
                if (response.status != 200) return reject(response.statusText);
                resolve();
            }).catch(error => reject(error));
        });
    }

}

export default LiveUserAPI;