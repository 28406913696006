export class Tag {
    name: string;
    constructor(name: string) {
        this.name = name;
    }
}

interface ITagsAPI {
    addTag(tag: Tag): Promise<void>;
    deleteTag(tag: Tag): Promise<void>;
    deleteTags(tag: Tag[]): Promise<void>;
    editTag(tag: Tag): Promise<void>;
    getTags(): Promise<Tag[]>;
}

export default ITagsAPI;