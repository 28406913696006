import { NotificationMechanism } from '@api/devicetypes/DeviceTypeAPI';
import DeviceTypeAPIFactory from '@api/devicetypes/DeviceTypeAPIFactory';
import { DeviceUpdateStatus, UpdateStatus } from '@api/updates/UpdatesAPI';
import UpdatesAPIFactory from '@api/updates/UpdatesAPIFactory';
import { DropdownFilterInput, TextFilterInput } from '@components/ui/inputs/filter/FilterInput';
import DashboardLayout from '@components/ui/layout/DashboardLayout/DashboardLayout';
import ExpandableFilterLayout from '@components/ui/layout/ExpandableFilterLayout/ExpandableFilterLayout';
import withData, { DataProp } from '@components/wrappers/DataLoader/DataLoader';
import UpdateFilterContext from '@context/UpdateFilterContext';
import React, { useContext, useEffect, useState } from 'react';
import UpdateStatusRow from './UpdateStatusRow';

const maxEntries = 8;
const UpdatesDashboard: React.FunctionComponent<DataProp<[DeviceUpdateStatus[], NotificationMechanism[], UpdateStatus[], number]>> = ({data}) => {
    const filtersContext = useContext(UpdateFilterContext);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [filteredUpdateStatus, setFilteredUpdateStatus] = useState<DeviceUpdateStatus[]>(data[0]);
    const [updateCount, setUpdateCount] = useState<number>(data[3]);
    const [queryParams, setQueryParams] = useState<string>('');
    const [nameFilter, setNameFilter] = useState<string>((filtersContext.getUpdateFilters().name)?filtersContext.getUpdateFilters().name:'');
    const [searchText, setSearchText] = useState<string>('');
    const [notificationMethodFilter, setNotificationMethodFilter] = useState<number>((filtersContext.getUpdateFilters().notificationMethod)?filtersContext.getUpdateFilters().notificationMethod:-1);
    const [updateStatusFilter, setUpdateStatusFilter] = useState<number>((filtersContext.getUpdateFilters().status)?filtersContext.getUpdateFilters().status:-1);
    const [selectedId, setSelectedId] = useState<string | null>(null);
    const notificationMechanisms: NotificationMechanism[] = data[1];
    const updateStatuses: UpdateStatus[] = data[2];
    const tableHeaders: string[] = ['Device', 'State', 'Notification method', 'Timestamp'];
    const filters: React.ReactNode[] = [
        <TextFilterInput label="Name" placeholder="Type to filter by name" updateValue={(newValue: any) => {setNameFilter(newValue)}} presetValue={nameFilter}/>,
        <DropdownFilterInput label="Notification method" selectedOption={notificationMethodFilter+ ""} onSelect={(newValue: any) => { setNotificationMethodFilter(newValue); }} placeholder="Notification Method" dropdownOptions={notificationMechanisms.map(mech => { return { id: mech.id+"", label: mech.name } })}/>,
        <DropdownFilterInput label="Status" selectedOption={updateStatusFilter+ ""} onSelect={(newValue: any) => { setUpdateStatusFilter(newValue); }} placeholder="Status" dropdownOptions={updateStatuses.map(mech => { return { id: mech.id+"", label: mech.name } })}/>
    ];
    useEffect(() => {
        submitFilters();
    }, [searchText, nameFilter, notificationMethodFilter, updateStatusFilter]);
    useEffect(() => {
        Promise.all([UpdatesAPIFactory.getUpdatesAPI().getDevicesUpdateInfo(queryParams, currentPage, maxEntries), UpdatesAPIFactory.getUpdatesAPI().getUpdateCount(queryParams)]).then(data => {
            setFilteredUpdateStatus(data[0]);
            setUpdateCount(data[1]);
        })
    }, [currentPage, queryParams]);
    const submitFilters = () => {
        let queryParams = [];
        if (searchText != "") {
            queryParams.push("device=" + searchText);
        } else {
            if (nameFilter != "") {
                queryParams.push("device=" + nameFilter);
            }
        }
        if (notificationMethodFilter != -1) {
            queryParams.push("notificationMethod=" + notificationMethodFilter);
        }
        if (updateStatusFilter != -1) {
            queryParams.push("updateStatus=" + updateStatusFilter);
        }
        filtersContext.setUpdateFilters({name:nameFilter, notificationMethod: notificationMethodFilter, status: updateStatusFilter});
        setQueryParams(queryParams.join('&'));
    };
    const resetFilters = () => {
        setNameFilter('');
        setUpdateStatusFilter(-1);
        setNotificationMethodFilter(-1);
    }
    return (
        <DashboardLayout 
            title="Update Status"
            tableFooter={<></>}
            filter={{filter: ExpandableFilterLayout, props:{
                placeholder: 'Search by name', onReset: resetFilters, filterItems: filters, onTextSearchChange: (newValue: string) => {setSearchText(newValue)}}}}
            tableProps={{
                tableHeaders: tableHeaders,
                tableFooterInfo: {currentPage: currentPage,
                    numberOfEntries: updateCount,
                    entriesPerPage: maxEntries,
                    pageSelected: (newPage: number) => {setCurrentPage(newPage)}
                }
            }}
        >
            {filteredUpdateStatus.map(status => <UpdateStatusRow status={status} notificationMechanisms={notificationMechanisms} possibleStatuses={updateStatuses} showHistory={selectedId == status.id} showHistoryToggled={(show) => { show ? setSelectedId(status.id) : setSelectedId(null)}}  />)}
        </DashboardLayout>
    )
}

export default withData(() => Promise.all([UpdatesAPIFactory.getUpdatesAPI().getDevicesUpdateInfo('', 1, maxEntries), DeviceTypeAPIFactory.getDeviceTypeAPI().getNotificationMechanisms(), UpdatesAPIFactory.getUpdatesAPI().getUpdateStatusList(), UpdatesAPIFactory.getUpdatesAPI().getUpdateCount()]))(UpdatesDashboard);