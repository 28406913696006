import React from 'react';
import { Link } from 'react-router-dom';
import "./PageNotFound.css";

const PageNotFound: React.FunctionComponent = (() => {
    return (
        <div className="Container">
            <h1>
                Unfortunately we could not the find page you are looking for (404)
            </h1>
            <Link to="/" style={{color:'white'}}>Go back home?</Link>
        </div>
    );
});

export default PageNotFound;