import React, { useEffect, useRef, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import SubmitButton from '../SubmitButton/SubmitButton';
import { useToasts } from 'react-toast-notifications';

import './AddButton.css';
import { useTranslation } from 'react-i18next';

interface IAddButton {
    submitLabel: string;
    expanded: boolean;
    editing: boolean;
    enabled: boolean;
    onSubmit: () => Promise<any>;
    onDelete?: () => Promise<any>;
    onEdit: () => Promise<any>;
    onClose: () => void;
    openClicked: () => void;
}

const AddButton: React.FunctionComponent<IAddButton> = ({ enabled, children, submitLabel, onSubmit, onDelete, expanded, onClose, onEdit, editing, openClicked}) => {
    const { t } = useTranslation();
    const enabledRef = useRef(enabled);
    enabledRef.current = enabled;

    const [_submitting, setSubmitting] = useState(false);
    const { addToast } = useToasts();
    const handleOperationResult = (operation: () => Promise<any>, operationName: string) => {
        setSubmitting(true);
        operation().then(() => {
            setSubmitting(false);
            onClose();
            addToast(`${operationName} operation successful`, { appearance: 'success', autoDismiss: true });
        }).catch((error) => {
            setSubmitting(false);
            addToast(`${operationName} operation failed with error:` + error, { appearance: 'error', autoDismiss: true })
        });
    }
    const deleteForm = () => {
        if (!onDelete) return;
        handleOperationResult(onDelete, 'Delete');
    }
    const panelCSS = [
        "AddButtonPopUp"
    ];
    if (expanded) {
        panelCSS.push('expanded');
    }
    return (
        <div>
            <div className="AddButton" onClick={() => { openClicked() }}>
                <FaPlus size={14} color="#1E90FF" /><span style={{ marginLeft: '5px' }}>{t('Add new')}</span>
            </div>
            <div className={panelCSS.join(' ')}>
                {
                    children
                }
                <hr />
                {
                    editing &&
                    <SubmitButton isDelete={true} label="Delete" isLoading={_submitting} disabled={_submitting} isSubmit={false} onClick={deleteForm} />
                }
                <SubmitButton label={submitLabel} isLoading={_submitting} disabled={_submitting || !enabled} onClick={() => {}} />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                    <span onClick={() => { onClose()}}>{t('Cancel')}</span>
                </div>
            </div>
        </div>
    )
};

export default AddButton;