import React from 'react';
import './TextEntry.css';

interface ITextEntry {
    label: string;
}

const TextEntry: React.FunctionComponent<ITextEntry> = ({label}) => {
    return (
        <>{label}</>
    )
}

export default TextEntry;